import { ChildModel, ModelBase } from 'store/model-base';
import { Variant } from './variant';
import { Product } from './product';
import { PriceOption } from 'store/plan/models';
import { Availability, ByodImeiCapability, PromotionType } from 'core/constants';

export class ProductDetails extends Product {
    public static storeName: string = 'productDetails';

    public description: string;
    public details: ProductFeature[];
    public variants: Variant[];

    public isPreorder: boolean;
    public prepaidPromo: PriceOption;
    public discountPromo: PriceOption;
    public subsidyPromo: PriceOption;
    public hasInStockVariant: boolean;
    public tps: string;
    public simType: ByodImeiCapability;
    public isPsimEsim: boolean;

    protected static get hasMany(): ChildModel[] {
        return [{
            attrName: 'variants',
            model: Variant
        }];
    }

    public static create<T extends ModelBase>(initData: object): T {
        const toReturn: ProductDetails = super.create<ProductDetails>(initData);

        toReturn.isPreorder = toReturn.status === Availability.PREORDER;
        toReturn.colors = toReturn._colors;

        let promoVariant: Variant = toReturn.variants.find((variant: Variant) => Boolean(variant.prepaidPromo));
        toReturn.prepaidPromo = promoVariant ? promoVariant.prepaidPromo : undefined;
        promoVariant = toReturn.variants.find((variant: Variant) => Boolean(variant.discountPromo));
        toReturn.discountPromo = promoVariant ? promoVariant.discountPromo : undefined;
        promoVariant = toReturn.variants.find((variant: Variant) => Boolean(variant.subsidyPromo));
        toReturn.subsidyPromo = promoVariant ? promoVariant.subsidyPromo : undefined;
        toReturn.isPsimEsim = toReturn.simType === ByodImeiCapability.PSIM_ESIM;
        toReturn.hasInStockVariant = Boolean(toReturn.variants.find((variant: Variant) => !variant.isOutOfStock));

        /* eslint-disable @typescript-eslint/no-explicit-any */
        return <T> <any> toReturn;
        /* eslint-enable @typescript-eslint/no-explicit-any */
    }

    public variantByColorAndStorage(color: ProductColor, storage: string): Variant {
        const result: Variant = this.variants.find((variant: Variant) => variant.color.hex === color.hex && variant.capacity === storage && (this.isTablet || !variant.isOutOfStock));

        return result || this.variantByColorName(color.name);
    }

    //Added this to filter variant based on colorname and storage
    public variantByColorNameAndStorage(color: ProductColor, storage: string): Variant {
        const result: Variant = this.variants.find((variant: Variant) => variant.color.hex === color.hex && variant.capacity === storage && variant.color.name === color.name && !variant.isOutOfStock);

        return result || this.variantByColorName(color.name);
    }

    public variantByColorName(colorName: string): Variant {
       
        const uriColor: string = decodeURIComponent(colorName.replace(/\+/g, ' '));
        const result: Variant = this.variants.find((variant: Variant) => variant.color.name === uriColor && (this.isTablet || !variant.isOutOfStock));

        return result || this.variants.find((variant: Variant) => variant.color.name === uriColor);
    }

    public get cheapestVariant(): Variant {
        return this.cheapestFinancedVariant(this.variants);
    }

    public get isPrepaidPromo(): boolean {
        return Boolean(this.prepaidPromo);
    }

    public get isDiscountPromo(): boolean {
        return Boolean(this.discountPromo);
    }

    public get isSubsidyPromo(): boolean {
        return Boolean(this.subsidyPromo);
    }

    public get displayPromoValue(): string {
        if (this.prepaidPromo && this.prepaidPromo.promotionalType === PromotionType.GIFT_CARD) {
            return `$${this.prepaidPromo.promotionalValueTotal}`;
        }
        if (this.discountPromo && this.discountPromo.promotionalType === PromotionType.DOLLAR_AMOUNT) {
            return `$${this.discountPromo.promotionalValueTotal}`;
        }
        if (this.discountPromo && this.discountPromo.promotionalType === PromotionType.PERCENTAGE) {
            return `${this.discountPromo.promotionalValueTotal}%`;
        }
        if (this.subsidyPromo && this.subsidyPromo.promotionalType === PromotionType.SUBSIDY) {
            return `$${this.subsidyPromo.promotionalValueTotal}`;
        }
    }

    public get accessoryInStockColors(): ProductColor[] {
        return this.variants.filter((variant: Variant) => variant.isInStock).map((variant: Variant) => variant.color);
    }

    private get _colors(): ProductColor[] {
        const uniqueColors: ProductColor[] = [];
        const uniqueColorName: string[] = [];
        const uniqueColorHex: string[] = [];
        this.variants.forEach((variant: Variant) => {
            if (!uniqueColorName.includes(variant.color.name) || !uniqueColorHex.includes(variant.color.hex)) {
                uniqueColors.push(variant.color);
                uniqueColorName.push(variant.color.name);
                uniqueColorHex.push(variant.color.hex);
            }
        });

        return uniqueColors;
    }

    private cheapestFinancedVariant(variants: Variant[]): Variant {
        const sortedArray: Variant[] = variants.sort((variant1: Variant, variant2: Variant) => {
            const equal: boolean = !(variant1.financePrice - variant2.financePrice);
            if (equal) {
                return parseInt(variant1.capacity) - parseInt(variant2.capacity);
            }

            return variant1.financePrice - variant2.financePrice;
        });

        const cheapestInStock: Variant = sortedArray.find((variant: Variant) => !variant.isOutOfStock);

        return cheapestInStock || sortedArray[0];
    }
}
