import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, DatePipe, DecimalPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Angular2PromiseButtonModule } from 'angular2-promise-buttons';
import { UIRouterModule } from '@uirouter/angular';
import { SwiperModule } from 'ngx-swiper-wrapper';

import { PhonePipe } from './pipes/phone';
import { XmCurrencyPipe } from './pipes/currency';
import { IMEIPipe } from './pipes/imei';
import { StripHtml } from './pipes/strip-html';
import { ConvertUnitPipe } from './pipes/unit';
import { KeysPipe } from './pipes/keys';
import { SafeHtmlPipe } from './pipes/html';

import { XmMask } from './directives/mask';
import { XmButtonClick } from './directives/button';
import { XmIgnoreGenericClickEvent } from './directives/ignore-generic-click-event';
import { XmAriaDescribedBy } from './directives/aria-describedby';
import { XmAriaInvalid } from './directives/aria-invalid';
import { XmAriaFocus } from './directives/aria-focus';
import { XmAriaHidden } from './directives/aria-hidden';
import { XmAriaDisabled } from './directives/aria-disabled';
import { XmRadioControl } from './directives/radio';
import { CbmTooltip } from './directives/tooltip';

import { SwiperA11yDirective } from './directives/carousel';
import { XmMediaImageComponent } from './media/image/image';
import { XmResponsiveImageComponent } from './media/responsive-image/responsive-image';
import { XmRadioComponent } from './radio/radio';
import { XmCheckboxComponent } from './checkbox/checkbox';
import { CmsTextComponent } from './cms-text/text';
import { XmCmsActionComponent } from './cms-action/cms-action';
import { XmExpandableComponent } from './expandable/expandable';
import { XmValidationComponent } from './validation/validation';
import { XmQuantityControlComponent } from './quantity/quantity';
import { XmEmptyComponent } from './empty/empty';
import { XmDropdownComponent } from './dropdown/dropdown';
import { XmSlideUpComponent } from './animations/slide-up/slide-up';
import { XmAutoCompleteComponent } from './autocomplete/autocomplete';
import { XmSchemaComponent } from './schema/schema';
import { XmBreadcrumbsComponent } from './breadcrumb/breadcrumbs';
import { XmDecoratedTextComponent } from './decorated-text/decorated-text';
import { XmBoxRadioComponent } from './box-radio/box-radio';
import { XmNotificationComponent } from './notification/notification';
import { XmStatusComponent } from './status/status';
import { XmNegotiableItemComponent } from './negotiable-item/negotiable-item';
import { XmDropdownPaginationComponent } from './dropdown-pagination/dropdown-pagination';

@NgModule({
    declarations: [
        PhonePipe,
        IMEIPipe,
        ConvertUnitPipe,
        KeysPipe,
        SafeHtmlPipe,
        StripHtml,
        XmCurrencyPipe,
        XmMask,
        XmButtonClick,
        XmIgnoreGenericClickEvent,
        XmAriaDescribedBy,
        XmAriaInvalid,
        XmAriaFocus,
        XmAriaHidden,
        XmAriaDisabled,
        XmRadioControl,
        CbmTooltip,
        SwiperA11yDirective,
        XmRadioComponent,
        XmCheckboxComponent,
        XmMediaImageComponent,
        XmResponsiveImageComponent,
        CmsTextComponent,
        XmDecoratedTextComponent,
        XmCmsActionComponent,
        XmExpandableComponent,
        XmValidationComponent,
        XmQuantityControlComponent,
        XmEmptyComponent,
        XmDropdownComponent,
        XmDropdownPaginationComponent,
        XmSlideUpComponent,
        XmAutoCompleteComponent,
        XmSchemaComponent,
        XmBreadcrumbsComponent,
        XmBoxRadioComponent,
        XmNotificationComponent,
        XmStatusComponent,
        XmNegotiableItemComponent
    ],
    exports: [
        Angular2PromiseButtonModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UIRouterModule,
        SwiperModule,
        DecimalPipe,
        PhonePipe,
        XmCurrencyPipe,
        IMEIPipe,
        ConvertUnitPipe,
        KeysPipe,
        SafeHtmlPipe,
        StripHtml,
        XmMask,
        XmButtonClick,
        XmIgnoreGenericClickEvent,
        XmAriaDescribedBy,
        XmAriaInvalid,
        XmAriaFocus,
        XmAriaHidden,
        XmAriaDisabled,
        XmRadioControl,
        CbmTooltip,
        SwiperA11yDirective,
        XmRadioComponent,
        XmCheckboxComponent,
        XmMediaImageComponent,
        XmResponsiveImageComponent,
        CmsTextComponent,
        XmDecoratedTextComponent,
        XmCmsActionComponent,
        XmExpandableComponent,
        XmValidationComponent,
        FormsModule,
        ReactiveFormsModule,
        XmEmptyComponent,
        XmQuantityControlComponent,
        XmDropdownComponent,
        XmDropdownPaginationComponent,
        XmSlideUpComponent,
        XmAutoCompleteComponent,
        XmSchemaComponent,
        XmBreadcrumbsComponent,
        XmBoxRadioComponent,
        XmNotificationComponent,
        XmStatusComponent,
        XmNegotiableItemComponent
    ],
    imports: [
        Angular2PromiseButtonModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        UIRouterModule,
        SwiperModule
    ],
    providers: [
        DatePipe,
        CurrencyPipe,
        PhonePipe,
        XmCurrencyPipe,
        StripHtml
    ]
})
export class SharedModule {}
