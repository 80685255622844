import { Inject, Injectable, OnDestroy } from '@angular/core';
import { StateService, Transition, UIRouter } from '@uirouter/core';
import { Subscription } from 'rxjs';

import * as DD from './digital-data/index';
import { WindowReference } from '../window';
import { CimaToken } from '../cima/token';
import { Util } from '../util';
import { XmStore, XmStoreUtil } from '../store';
import { SessionStorage } from '../storage/session';
import { CimaCore } from '../cima/core';
import { AdobeReporter } from '../adobe-reporter';
import { IXMOptions } from 'core/interfaces';
import { CONFIG_TOKEN, IS_CBM_CUSTOMER, MARKETING_STATES, StorageToken } from 'core/constants';
import { BuyInfo, User } from 'store/user/models';
import { DataLayer } from '..';

const SITE_TYPE_BUSINESS: string = 'business';

@Injectable({
    providedIn: 'root'
})
export class Analytics implements OnDestroy {
    private static BTN: string = 'BUTTON';
    private static A: string = 'A';
    private static events: TDigitalEvent[] = [];
    public customParams: object = {};
    public customPageName: string = '';
    public customEventAction: string = '';
    public customEventCategory: string = '';
    public customerType: string = '';
    public buyflowStep: string;
    public buyflowtype: string;
    public isModestoCustomer: boolean;

    private cimaToken: CimaToken;
    private config: IXMOptions;
    private xmStore: XmStore;
    private active: boolean;
    private breakpoint: string;
    private uiRouter: UIRouter;
    private user: User;
    private sessionStorage: SessionStorage;
    private windowReference: WindowReference;
    private state: StateService;
    private transitionSubscription: Subscription[] = [];
    private fullPath: string = '';
    private cartId: string;
    private cimaCore: CimaCore;
    private flowType: string = '';
    private adobeReporter: AdobeReporter;
    private datalayer: DataLayer;
    private digitalData: TDigitalData ={};
  

    // Stores all Items that were added to cart during the session. Used to identify removal Items
    private totalCartItems: TDigitalProductInfo[] = [];
    // Stores current items in Cart
    private currentCartItems: TDigitalProductInfo[] = [];

    constructor(cimaToken: CimaToken,
        @Inject(CONFIG_TOKEN) config: Partial<IXMOptions>,
        sessionStorage: SessionStorage,
        state: StateService,
        windowReference: WindowReference,
        uiRouter: UIRouter,
        xmStore: XmStore,
        cimaCore: CimaCore,
        adobeReporter: AdobeReporter,
        datalayer: DataLayer
    ){
        Object.assign(this, {
            config,
            cimaToken,
            sessionStorage,
            state,
            uiRouter,
            windowReference,
            xmStore,
            cimaCore,
            adobeReporter,
            datalayer
        });

        window.logAnalytics = false;
    }

    /* TBD - Track custom events, form tracking, Auth change */


    public setupRouterListener(): void {
        if (location.search) {
            this.fullPath = location.href;
        }
        // using onFinish function to chain a new Promise
        this.uiRouter.transitionService.onFinish({}, (transition: Transition) => {
            // always set pageName before any analytics "logic" begins
            this.setPageInfo(transition);
           
            // the delay between sending customEvent through the data layer has millisecond delay getting through the event queue
            // by using the timeout, we can hold the route from completing ('success') given enough time before AB Tests resolve
            return new Promise((resolve: Function) => {
                setTimeout(resolve, 200); // delaying for the custom event (firePageInfo) to be processed
            });
        });

        // Fire Page Info after a successful transitions
        this.transitionSubscription.push(XmStoreUtil.subscribe(this.uiRouter.globals.success$, (transition: Transition) => {
            if (!(DD.PREVENT_PAGEVIEW_ROUTES.includes(transition.to().name))) {
                this.firePageInfo(transition);
            }
        }));

        this.transitionSubscription.push(XmStoreUtil.subscribe(this.uiRouter.globals.start$, () => {
            this.customPageName = '';
        }));
    }
   
    public sendTargetEvent(mbox: string, params: object): void {
        if (this.canSendAnalytics && window.adobe && window.adobe.target) {
            this.adobeReporter.trackTargetEvent(mbox, params);
        }
    }

    //Used
    public watchClickGlobal(): void {
        if (this.isDataLayerActive) {
            return;
        }

        window.document.addEventListener('click', (event: Event) => {
            const tag: HTMLElement = <HTMLElement> event.target;

            if (tag.tagName === Analytics.BTN || tag.tagName === Analytics.A) {
                this.trackClickEvent(tag);
            } else {
                const clickableElement: HTMLElement = this.findClickableElement(tag.parentElement);

                if (clickableElement) {
                    this.trackClickEvent(clickableElement);
                }
            }
        });
    }

    public ngOnDestroy(): void {
        Util.unsubscribeAll(this.transitionSubscription);
    }

    /**
     * A function to send custom events which need to be tracked with specific 'DTM Tracking String'.
     * Call this function with following arguments:
     *
     * eventName: '' // Name of the event which matches the events constant to pull other data from constants.
     *
     * params: {} // optional custom params to inject either inside 'digitalData.event[].eventInfo' or 'digitalData' itself.
     *
     * overrideGlobalDigitalData: true | false
     * true -> will merge 'params' directly to 'digitalData'.
     * false -> will merge 'params' into 'digitalData.event[].eventInfo', which is default.
     *
     * sendingMultipleEvents: true | false   (will prevent the event overrides on a same page)
     * true -> if the same page is sending multiple events to the same data layer.
     * false -> if the same page is sending a single event, which is default.
     */
    public sendCustomEvent(eventName: string, params?: object, overrideGlobalDigitalData?: boolean, sendingMultipleEvents?: boolean, transition?: Transition): void {
        const newEventInfo: TCustomEvent = DD.CUSTOM_EVENTS.events.find((item: TCustomEvent) => item.eventName === eventName);
        if (newEventInfo && this.isDataLayerActive) {
            this.trackCustomDigitalEvent(
                newEventInfo.eventName, newEventInfo.action, this.pipifiedRoute, params || {}, 
                overrideGlobalDigitalData, sendingMultipleEvents, newEventInfo.dtmEvent || DD.DTM_SEND_EVENT, transition);
        }
    }

    public sendCustomLoadEvent(params: object): void {
        Util.mergeDeepWith(window.digitalData, params);
    }

    /**
     * Send Cart updates add/edit/remove to datalayer
     * @param eventName: '' // Name of the event which matches the events constant to pull other data from constants.
     * @param cartItemId: '' // need to pass the same id that get passed to Cart Api
     * @param removalItem: true | false // will decide to use the totalCartItems or currentCartItems
     */
    public sendCartUpdateEvent(eventName: string, cartItemId: string, removalItem?: boolean, nickname?: string): void {
        if (this.isDataLayerActive) {
            return;
        }
        const toReturnProductInfo: TDigitalProductInfo = removalItem ?
            this.totalCartItems.find((item: TDigitalProductInfo) => item.cartItemId === cartItemId) : this.currentCartItems.find((item: TDigitalProductInfo) => item.cartItemId === cartItemId || item.nickname === nickname);

        if (toReturnProductInfo) {
            this.sendCustomEvent(eventName, {
                product: toReturnProductInfo
            }, false, true);
        }
    }

    public firePageInfo(transition: Transition): void {
        if (this.isDataLayerActive) {
            const stateName: string = transition.to().name;
            const isMatch: boolean = MARKETING_STATES.some((stateRegex: RegExp) => stateRegex.test(stateName));
            if (isMatch && this.fullPath) {
                window.history.replaceState(undefined, undefined, this.fullPath);
            }

            // reset the full path as we only want this to happen on app load (once)
            this.fullPath = '';

            this.logAnalytics('Analytics event: custom-pageLoad fired!');

            this.datalayer.sendPageDataOnTransition(transition, this.digitalData);

            if (this.canSendAnalytics) {
                this.adobeReporter.pageBottom();
            }
        }
    }

    public fireValidationError(errorText: string): void {
        if (this.isDataLayerActive) {
            return;
        }

        const events: TDigitalEvent[] = window.digitalData.event;

        if (events.some((eventData: TDigitalEvent) => (eventData.eventInfo.eventAction && Util.dotWalk(eventData.eventInfo, 'eventAction') === errorText))) {
            return;
        }

        this.sendCustomEvent('FE Error', { eventAction: errorText }, false, true);
    }

    public updateErrorMsg(errorMessage?: string): void {
        if (this.canSendAnalytics && !this.isDataLayerActive) {
            window.digitalData.page.attributes.errorMessage = errorMessage;
        }
    }

    public updateFlowType(message: string): void {
        this.flowType = message;
    }

    public fireIdentityCheckError(errorText: string): void {
        if (this.isDataLayerActive) {
            return;
        }

        const events: TDigitalEvent[] = window.digitalData.event;

        if (events.some((eventData: TDigitalEvent) => (eventData.eventInfo.eventAction && Util.dotWalk(eventData.eventInfo, 'eventAction') === errorText))) {
            return;
        }

        this.sendCustomEvent('FE Error', { eventAction: errorText }, false, true);
    }

    public setAndFireCustomPageInfo(transition: Transition): void {
        this.setPageInfo(transition);
        this.firePageInfo(transition);
    }

    public get hasDigitalData(): boolean {
        return this.active;
    }    
    

    public setDefaultDigitalData(stateName: string): void {
        XmStoreUtil.subscribe(this.xmStore.peek<User>(User, { returnUndefined: true }), (user: User) => {
            this.user = user; 
        });

        //setbuyflow
        if (stateName.includes(DD.CheckoutAttributes.BUYFLOW_STEP)) {
            this.buyflowStep = DD.CheckoutAttributes.BUYFLOW_STEP;
            this.buyflowtype = DD.CheckoutAttributes.BUYFLOW_TYPE;
        } else if (stateName.includes(DD.ConfirmationAttributes.BUYFLOW_STEP)) {
            this.buyflowStep = DD.ConfirmationAttributes.BUYFLOW_STEP;
            this.buyflowtype = DD.ConfirmationAttributes.BUYFLOW_TYPE;
        }         

        //setCustomerType
        if (this.sessionStorage.hasKey(StorageToken.CBM_TEST_USER) && this.sessionStorage.get(StorageToken.CBM_TEST_USER)){
            this.customerType = DD.CUSTOMER_TYPE.tester;
        } else if ((this.user && (this.user.buyInfo.existingModestoCustomer || this.user.isCBMCustomer)) || this.sessionStorage.get<string>(StorageToken.CUSTOMER_TYPE)) {
            this.customerType = DD.CUSTOMER_TYPE.existing;
            this.sessionStorage.set(StorageToken.CUSTOMER_TYPE, DD.CUSTOMER_TYPE.existing );
        } else if (this.user && !this.user.buyInfo.existingModestoCustomer && !this.user.isCBMCustomer) {
            this.customerType = DD.CUSTOMER_TYPE.new;
        } else {
            this.customerType = DD.CUSTOMER_TYPE.unknown;
        }

        //getViewportScreenSize
        this.windowReference.breakpoint.subscribe((breakpoint: Breakpoint) => {
            this.breakpoint = breakpoint.currentBreakpoint;
        });

        const siteInfo: TDDSiteInfo = {
            siteType: 'sales',
            siteVersion: this.breakpoint || '',
            visitorId: this.sessionStorage.get<string>(StorageToken.VISITOR_SESSION_ID)
        };

        this.customParams = { siteInfo };
        Util.mergeDeepWith(this.digitalData, this.customParams);
        this.setUserDigitalData();
        this.eventData();
    }

    //set page attributes 
    public setPageAttributes(itemId: string): void {   

        const page: TDDPage = {
            attributes: {
                isPageLoadVariables: false,
                flowType: '',
                errorMessage: '',
                removeProductID: itemId ? itemId : ''
            }
        };
       
        this.customParams = { page };   
        Util.mergeDeepWith(this.digitalData, this.customParams); 
        this.datalayer.sendUpdate(this.customParams );
    }

    //set event digital data
    public eventData(): void {             

        const event: TDigitalEvent[] = [];   
       
        this.customParams = { event };
        Util.mergeDeepWith(this.digitalData, this.customParams);
    }

    //set User and Liability digital data
    public setUserDigitalData(): void {    
        const users: TDDUser[] = [{
            profile: [{
                profileInfo: {
                    authenticationStatus: this.cimaToken.isLoggedIn ? DD.AUTHENTICATION_STATUS.authenticated : DD.AUTHENTICATION_STATUS.unauthenticated,
                    customerType: this.customerType,
                    accountType: this.uiRouter.urlService.url().endsWith(DD.SECONDARY_USER) ? DD.ACCOUNT_TYPE.sub : this.cimaToken.isLoggedIn && DD.ACCOUNT_TYPE.main || undefined,
                    userGUID: this.cimaToken.isLoggedIn ? this.cimaToken.decodedIdToken.tid : '',
                    custGUID: this.cimaToken.isLoggedIn ? this.cimaToken.decodedIdToken.sub : '',
                    segmentLevel: this.cimaToken.isLoggedIn ? this.user && this.user.account && this.user.account.level : undefined,
                    isCBMCustomer: this.user && this.user?.isCBMCustomer ? IS_CBM_CUSTOMER.YES : IS_CBM_CUSTOMER.NO,
                    currentLinesNumber: this.user && this.user?.allowedLines && this.user.allowedLines.hasApiData ? this.user.allowedLines.activeLines: 0,
                    linesLimit: this.user && this.user.allowedLines && this.user.allowedLines.hasApiData ? this.user.allowedLines.maxLines: 0
                }
            }]
        }];    
       
        this.customParams = { users };
        Util.mergeDeepWith(this.digitalData, this.customParams);
    }

    public setProfileStatus(buyInfo?: BuyInfo): void {     
        const profileStatus: TDDProfileStatus = {
            liabilityType: buyInfo?.liabilityType,
            sessionId: buyInfo?.sessionId
        };
        
        this.customParams = { profileStatus };   
        Util.mergeDeepWith(this.digitalData, this.customParams); 
        this.datalayer.sendUpdate(this.customParams );
    }  

    //set up messages from credit check API 
    public setDigitalDataCreditCheckMessages(creditMessages: Message[]): void {
        const creditCheck: TDigitalDataCreditCheck = {            
            messages: creditMessages
        };  
       
        this.customParams = { creditCheck };
        Util.mergeDeepWith(this.digitalData, this.customParams); 
        this.datalayer.sendUpdate(this.customParams );
    }

    public setCart(cart: TDDCart): void {   
        this.customParams = { cart };
        Util.mergeDeepWith(this.digitalData, this.customParams); 
        this.datalayer.sendUpdate(this.customParams);
    }    
   
    //Used
    private getDefaultDigitalData(stateName: string): TDigitalData {
        const paths: string[] = stateName && stateName.split('.') || [];
        const primaryCategory: string = paths[0] ? `${paths[0][0]?.toUpperCase()}${paths[0].substring(1)}` : '';
        const subCategory: string = paths[1] ? `${paths[1][0]?.toUpperCase()}${paths[1].substring(1)}` : '';
        const digitalData: TDigitalData = DD.DigitalData.forRoute(this.pipifiedRoute, primaryCategory, subCategory, {}, '', this.breakpoint, this.flowType).content;

        // Add auth parameters
        const canNDEL: boolean = this.cimaToken.isLoggedIn && this.user && (this.user.buyInfo.existingModestoCustomer || this.user.isCBMCustomer);
        const customerType: string = canNDEL ? DD.CUSTOMER_TYPE.existing : DD.CUSTOMER_TYPE.new;
        const authenticationStatus: string = this.cimaToken.isLoggedIn ? DD.AUTHENTICATION_STATUS.authenticated : DD.AUTHENTICATION_STATUS.unauthenticated;
        const userGUID: string = this.cimaToken.isLoggedIn ? this.cimaToken.decodedIdToken.tid : undefined;
        const custGUID: string = this.cimaToken.isLoggedIn ? this.cimaToken.decodedIdToken.sub : undefined;
        const segmentLevel: string = this.cimaToken.isLoggedIn ? this.user && this.user.account && this.user.account.level : undefined;
        const isCBMCustomer = this.user && this.user.isCBMCustomer ? IS_CBM_CUSTOMER.YES : IS_CBM_CUSTOMER.NO;
        const accountType: string = this.uiRouter.urlService.url().endsWith(DD.SECONDARY_USER) ? DD.ACCOUNT_TYPE.sub : this.cimaToken.isLoggedIn && DD.ACCOUNT_TYPE.main || undefined;

        digitalData.users[0].profile[0].profileInfo = {
            customerType,
            accountType,
            authenticationStatus,
            userGUID,
            custGUID,
            segmentLevel,
            isCBMCustomer
        };

        if (this.cimaCore && this.cimaCore.isBusinessUser) {
            digitalData.siteInfo.siteType = SITE_TYPE_BUSINESS;
        }

        this.setLinesLimitDigitalData(digitalData);

        digitalData.cart.cartId = this.cartId;
        digitalData.cart.products.productInfo = this.currentCartItems.length ? this.currentCartItems : undefined;

        if (stateName.includes(DD.CheckoutAttributes.BUYFLOW_STEP)) {
            digitalData.cart.attributes.buyflowstep = DD.CheckoutAttributes.BUYFLOW_STEP;
            digitalData.cart.attributes.buyflowtype = DD.CheckoutAttributes.BUYFLOW_TYPE;
        }

        return digitalData;
    }

    private get pipifiedRoute(): string {
        const currentUrlArray: string[] = this.uiRouter.urlService.url().split('/');

        let resultRoute: string = '';

        currentUrlArray.forEach((element: string) => {
            // Each ID will count pageload as a unique event, by skipping them we will get generic data of the states
            if (element.startsWith(DD.ID_IDENTIFER)) {
                return;
            }

            // remove query parameter first if any
            let transformed: string = element.split('?')[0];
            // remove access token from url
            transformed = transformed.split('#')[0];
            // lower-case each word
            transformed = transformed.toLocaleLowerCase();

            if (transformed && !transformed.endsWith('_id') && !/^[0-9]+$/.test(transformed)) {
                resultRoute += `|${transformed}`;
            }
        });

        return resultRoute ? `busn_cbm${resultRoute}${this.customPageName ? '|' + this.customPageName : '' }` : 'busn_cbm|home';
    }

    //Used
    private trackCustomDigitalEvent(eventName: string, eventAction: string, eventPage: string, params: object, overrideGlobalDigitalData?: boolean, sendingMultipleEvents?: boolean, trackString: string = '', transition?: Transition): void {
        const digitalData: TDigitalData = this.digitalData;

        // eslint-disable-next-line no-console
        console.log('trackCustomDigitalEvent', eventName, eventAction, eventPage, params, overrideGlobalDigitalData, sendingMultipleEvents, trackString, transition);

        const event: DD.DigitalEvent = new DD.DigitalEvent(eventName, eventAction || '', eventPage || '');
        if (params) {
            Object.keys(params).forEach((key: string) => {
                if (overrideGlobalDigitalData) {
                    digitalData[key] = params[key];
                } else {
                    event.eventInfo[key] = params[key];
                }
            });
    
            if (sendingMultipleEvents) {
                Analytics.events.push(event.toData());
                digitalData.event.push(...Analytics.events);
            } else {
                digitalData.event.push(event.toData());
            }
    
            if (!window.digitalData.event || !Array.isArray(window.digitalData.event)) {
                window.digitalData.event = [];
            }
            
            this.digitalData = digitalData;

            this.datalayer.sendCustomEventTransition(transition, this.digitalData);
            this.logAnalytics(`CBM Custom Event: ${event.eventInfo.eventAction}`);
            this.logAnalytics(JSON.stringify(window.digitalData, undefined, '\t'));
    
            if (this.canSendAnalytics) {
                this.adobeReporter.trackSatelliteEvent(trackString, event.toLaunchData());
            }
        }
    }

    /**
     * A function to merge custom route params (while transition to new state) into 'digitalData'.
     *
     * Define a new async function as 'resolveFn' in 'route.ts' file according to the requirements.
     * Fetch the data with 'await' and assign it to the 'analytics.customParams'.
     *
     * 'handleCustomRouteParams' function will be fired on successful transition in 'onload()' with custom params.
     */
    private handleCustomRouteParams(): void {
        if (Object.keys(this.customParams).length) {
            Util.mergeDeepWith(window.digitalData, this.customParams);
        }
        this.customParams = {};
    }


    private setLinesLimitDigitalData(digitalData: TDigitalData): void {
        if (this.user && this.user.allowedLines && this.user.allowedLines.hasApiData) {
            digitalData.users[0].profile[0].profileInfo.currentLinesNumber = this.user.allowedLines.activeLines;
            digitalData.users[0].profile[0].profileInfo.linesLimit = this.user.allowedLines.maxLines;
        }
    }

    private setPageInfo(transition: Transition): void {
        this.setDefaultDigitalData(transition.to().name);
        this.logAnalytics(JSON.stringify(window.digitalData, undefined, '\t'));
    }

    private findClickableElement(element: HTMLElement): HTMLElement {
        if (!element) {
            return;
        }

        if (element.tagName === Analytics.BTN || element.tagName === Analytics.A) {
            return element;
        } else {
            return this.findClickableElement(element.parentElement);
        }
    }

    //Used
    private trackClickEvent(target: HTMLElement): void {
        if (this.customEventAction) {
            this.trackGenericClickEvent('button click', this.customEventAction, this.pipifiedRoute);
        } else if (this.customEventCategory) {
            this.trackGenericClickEvent('button click', this.resolveButtonName(target), this.pipifiedRoute, {}, this.customEventCategory);
            this.customEventCategory = '';
        } else {
            this.trackGenericClickEvent('button click', this.resolveButtonName(target), this.pipifiedRoute);
        }

        this.customEventAction = undefined;
    }

    //Used
    private trackGenericClickEvent(eventName: string, eventAction: string, eventPage: string, params: object = {}, eventCategory?: string): void {        

        const digitalData: TDigitalData = this.getDefaultDigitalData(this.state.current.name);
        const event: DD.DigitalEvent = new DD.DigitalEvent(eventName, eventAction, eventPage, params, eventCategory);

        Analytics.events.push(event.toData());
        digitalData.event.push(...Analytics.events);
        window.digitalData = digitalData;

        this.logAnalytics(`Generic Click ${eventAction}`);
        this.logAnalytics(JSON.stringify(window.digitalData, undefined, '\t'));

        if (this.customParams) {
            this.handleCustomRouteParams();
        }

        if (this.canSendAnalytics) {
            this.adobeReporter.trackSatelliteEvent('send-Event', event.toLaunchData());
        }
    }

    private logAnalytics(data: string): void {
        if (window.logAnalytics) {
            /* eslint-disable  no-console */
            console.log(data);
            /* eslint-enable  no-console */
        }
    }

    private resolveButtonName(target: HTMLElement): string {
        const banner: string = target.getAttribute('banner');
        const aria: string = target.getAttribute('aria-label');
        const title: string = target.getAttribute('title');
        const alt: string = target.getAttribute('alt');
        const text: string = target.getAttribute('text');
        const innerText: string = target.innerText;

        return banner || aria || title || alt || text || innerText || '';
    }

    private get canSendAnalytics(): boolean {
        return window._satellite && typeof window._satellite.track === 'function';
    }

    private get isDataLayerActive(): boolean {
        return window.dataLayerLoaded && Boolean(this.config.DATA_LAYER_URL);
    }
}
