import { ChildModel, ModelBase } from 'store/model-base';
import { PlanGroup, PriceOption, ProtectionPlan, ProtectionPlanGroup, ProtectionPlanVariant } from 'store/plan/models';
import { Availability, PlanSku, PlanType } from 'core/constants';
import { Util } from 'services/util';
import { Promotion } from 'store/promotions/models';

export class Variant extends ModelBase {
    public availabilityDate: Date;
    public capacity: string;
    public catalogItemId: string;
    public color: ProductColor;
    public currency: string;
    public currencyString: string;
    public dateAdded: Date;
    public images: VariantImage;
    public plans: PlanGroup[];
    public prices: PriceOption[];
    public skuCode: string;
    public status: Availability;

    public prepaidPromo: PriceOption;
    public discountPromo: PriceOption;
    public subsidyPromo: PriceOption;
    public extendedPromo: Promotion;
    public extendedPromoDollar: Promotion;
    public extendedPromoPercent: Promotion;
    public tradeInPromo: PriceOption;

    public financedPlan: PriceOption;
    public payFullPlan: PriceOption;
    public xmppVariant: ProtectionPlanVariant;

    /* eslint-disable @typescript-eslint/no-explicit-any */
    public static create<T extends ModelBase>(initData: ApiResponse): T {
        const toReturn: TransformedData = initData;

        toReturn.images = toReturn.images || {};
        toReturn.images.hero = Variant.imageDefaults(toReturn.images.hero);
        toReturn.images.primary = Variant.imageDefaults(toReturn.images.primary);
        toReturn.images.alternate = Variant.imageDefaults(toReturn.images.alternate);

        toReturn.capacity = toReturn.capacity ? toReturn?.capacity?.toUpperCase() : '';

        toReturn.financedPlan = initData.prices ? initData.prices.find((plan: PriceOption) => PriceOption.create<PriceOption>(plan).isFinanced) : undefined;
        toReturn.payFullPlan = initData.prices ? initData.prices.find((plan: PriceOption) => PriceOption.create<PriceOption>(plan).isFull) : undefined;

        const variant: Variant = super.create<Variant>(toReturn);
        const protectionPlans: ProtectionPlanGroup = variant.plans ? <ProtectionPlanGroup> variant.plans.find((planOption: PlanGroup) => planOption.type === PlanType.ProtectionPlan) : undefined;
        const protectionOptions: ProtectionPlan = protectionPlans && protectionPlans.options[0];
        variant.xmppVariant = protectionOptions && protectionOptions.variants[0];
        variant.prepaidPromo = variant.prices.find((price: PriceOption) => price.isPrepaidPromo);
        variant.discountPromo = variant.prices.find((price: PriceOption) => price.isDollarDiscount || price.isPercentPromo);
        variant.subsidyPromo = variant.prices.find((price: PriceOption) => price.isSubsidyPromo);
        variant.extendedPromo = variant.prices.find((price: PriceOption) => price.isExtendedPromo) && variant.prices.find((price: PriceOption) => price.isExtendedPromo).extendedPromo;
        variant.tradeInPromo = variant.prices.find((price: PriceOption) => price.isTradeInPromo);

        return <T> <any> variant;
    }
    /* eslint-enable @typescript-eslint/no-explicit-any */

    protected static get hasOne(): ChildModel[] {
        return [{
            attrName: 'financedPlan',
            model: PriceOption
        }, {
            attrName: 'payFullPlan',
            model: PriceOption
        }, {
            attrName: 'extendedPromo',
            model: Promotion
        }, {
            attrName: 'xmppVariant',
            model: ProtectionPlanVariant
        }];
    }

    protected static get hasMany(): ChildModel[] {
        return [{
            attrName: 'prices',
            model: PriceOption
        }];
    }

    private static imageDefaults(image: Image): Image {
        const defaultImage: Image = { url: '', alt: '' };

        const imageData: Image = image || defaultImage;
        imageData.url = imageData.url || defaultImage.url;
        imageData.alt = imageData.alt || defaultImage.alt;

        return imageData;
    }

    constructor(variant: ApiResponse) {
        super(variant);

        this.availabilityDate = new Date(variant.availabilityDate);
        this.dateAdded = new Date(variant.dateAdded);
    }

    public get isPreorder(): boolean {
        return this.status === Availability.PREORDER;
    }

    public get isBackorder(): boolean {
        return this.status === Availability.BACKORDER;
    }

    public get isOutOfStock(): boolean {
        return this.status === Availability.OUT_OF_STOCK;
    }

    public get isInStock(): boolean {
        return this.status === Availability.AVAILABLE;
    }

    public get availabilityText(): string {
        if (this.isPreorder) {
            return 'Available for Preorder';
        } else if (this.isBackorder) {
            return 'This item is on Backorder';
        } else if (this.isOutOfStock) {
            return 'Out of Stock';
        }

        return 'In Stock';
    }

    public get availabilityFormatedDate(): string {
        return Util.dateFormat(this.availabilityDate.toString(), 'MMM dd, yyyy');
    }

    public get displayImages(): Image[] {
        const images: Image[] = [];

        const variantImages: VariantImage = this.images;
        if (variantImages.primary && variantImages.primary.url) {
            images.push(variantImages.primary);
        }

        if (variantImages.hero && variantImages.hero.url) {
            images.push(variantImages.hero);
        }

        if (variantImages.alternate && variantImages.alternate.length) {
            variantImages.alternate.forEach((image: Image) => {
                if (image.url) {
                    images.push(image);
                }
            });
        }

        return images;
    }

    public get isFinanced(): boolean {
        return this.originalFinancePrice !== this.financePrice;
    }

    public get isPayFull(): boolean {
        return this.originalFullPrice !== this.fullPrice;
    }

    public get originalFullPrice(): number {
        return this.payFullPlan ? this.payFullPlan.originalPrice : 0;
    }

    public get fullPrice(): number {
        return this.payFullPlan ? this.payFullPlan.total : 0;
    }

    public get originalFinancePrice(): number {
        return this.financedPlan ? this.financedPlan.originalPrice : 0;
    }

    public get financePrice(): number {
        return this.financedPlan ? this.financedPlan.total : 0;
    }

    public getPaymentPlanById(id: string): PriceOption {
        return id === PlanSku.FINANCED ? this.financedPlan : this.payFullPlan;
    }

    public get downPayment(): number {
        return this.financedPlan ? this.financedPlan.downPayment : 0;
    }

    public get financedPromoPrice(): number {
        return this.financedPlan && (this.financedPlan.isSubsidyPromo || this.isExtendedPromoPhase2) ? this.financedPlan.dollarPromoPrice : this.originalFinancePrice;
    }

    public get fullPromoPrice(): number {
        return this.payFullPlan && this.payFullPlan.isSubsidyPromo ? this.payFullPlan.promotionPrice : this.originalFullPrice;
    }

    public get isExtendedPromoPhase2(): boolean {
        return this.extendedPromo && this.extendedPromo.hasApiData && this.extendedPromo.isExtendedPromoPhase2;
    }

    public get isTradeInPromo(): boolean {
        return this.isTradeInPromo;
    }
}
