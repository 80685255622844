import { ModelBase } from 'store/model-base';

export class CmsCustomizer extends ModelBase {
    public static storeName: string = 'cmsCustomizer';

    public customizerPlan: CmsCustomizerPlan;
    public customizerWatch: CmsCustomizerWatch;
    public customizerTablet: CmsCustomizerTablet;
    public title: {
        grandfather: string;
        nonGrandfather: string;
        byod: string;
        line: string;
    };
    public your: string;
    public ndelOptionTitle: string;
    public newLineOption: string;
    public ndelLineOption: string;
    public dataOptionTitle: string;
    public byodBanner: CmsMediaOptionsImage;
    public byodMobileBanners: {
        backgroundImage: CmsMediaImage;
        leftImage: CmsMediaImage;
        rightImage: CmsMediaImage;
    };
    public byodPricing: {
        subheader: string;
        description: string;
        image: CmsMediaImage;
    };
    public byodImageSubhead: string;
    public planDescription: CmsPlanDescription;
    public ndelLine: {
        ndelLineHeader: string;
        xmppTitle: string;
        dppPaid: string;
        dppRemaining: string;
        dppHalfPaidHasXmpp: string;
        dppHalfPaidNoXmpp: string;
        dppNotHalfPaidHasXmpp: string;
        dppNotHalfPaidNoXmpp: string;
        ndelLineInfo: string;
        ndelClaimInfo: string;
        ndelLineHasXmppDisclaimer1: string;
        ndelLineHasXmppDisclaimer2: string;
        ndelLineHasXmppDisclaimer3: string;
        findStoreCtaText: string;
        claimCtaText: string;
    };
    public byTheGigIcon: CmsMediaImage;
    public unlimitedIcon: CmsMediaImage;
    public phoneNumberTitle: string;
    public watchUserTitle: string;
    public byodPhoneUserTitle: string;
    public phoneNumberAriaLabel: string;
    public portNumberLabel: string;
    public keepNumberLabel: string;
    public phoneInputLabel: string;
    public phoneHeaderClass: string;
    public phoneInputTemplate: string;
    public carrierLabel: string;
    public carrierHeaderClass: string;
    public carrierPlaceholder: string;
    public carriers: DropdownItem[];
    public phoneUserTitle: string;
    public nicknameInputErrors: XmInputError[];
    public phoneInputErrors: XmInputError[];
    public carrierInputErrors: XmInputError[];
    public portInputErrors: XmInputError[];
    public protectionInputErrors: XmInputError[];
    public ndelInputErrors: XmInputError[];
    public headingXmpp: string;
    public descriptionAppleXmpp: string;
    public descriptionNonAppleXmpp: string;
    public descriptionXmppMore: string;
    public xmppLegalSub: string;
    public xmppAppleCareIcon: CmsMediaImage;
    public xmppAppleCareText: string;
    public xmppLabel: string;
    public noXmppLabel: string;
    public noXmppPrice: string;
    public plusData: string;
    public estimatedMonthly: string;
    public prospectEstimatedMonthly: string;
    public details: string;
    public prospectDetails: string;
    public buttonText: string;
    public apiError: string;
    public duplicateImeiError: string;
    public duplicateIccidError: string;
    public pifNdelError: string;
    public portinSameNumberApiError: string;
    public lineTypeTooltip: DescriptiveModalData;
    public billImageOptions: CmsMediaImage;
    public xmppDisclaimers: CmsXmppDisclaimer[];
    public xmppHigherTierNote: string;
    public fiveGBadgeAvailable: CmsFiveGBadge;
    public includedWithPlans: CmsIncludedWithPlans;
    public fiveGExcluded: string;
    public variantErrorText: string;
    public informationButton: string;
    public unlimitedMultiLinePricing: CmsUnlimitedMultiLinePricing;
    public amexIcon: CmsMediaImage;
    public visaIcon: CmsMediaImage;
    public mcIcon: CmsMediaImage;
    public discoverIcon: CmsMediaImage;
    public namePlaceHolder: string;

    public eSimDetails: {
        byodEsimImageSubhead: string;
        images: CmsResponsiveImagesGroup;
    };

    public customizerTitle: {
        grandfather: string;
        nonGrandfather: string;
        byod: string;
        line: string;
        phoneLine: string;
        tabletLine: string;
    };
}
