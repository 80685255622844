<span *ngFor="let cmsReplace of cmsReplaces">
    <span [innerHtml]="cmsReplace.beforeText | safeHtml"></span>
    <a *ngIf="cmsReplace.internalLink"
        [ngClass]="{ 'xm-btn-primary-light': cmsReplace.displayAsPrimary, 'xm-btn-secondary-light': cmsReplace.displayAsSecondary, 'xm-btn-link-light': cmsReplace.displayAsLink,  'xm-btn-link-light-bold': cmsReplace.displayAsBold, 'inline-text-replace': cmsReplace.useParentStyles, 'link-body-style': linkBodyStyle }"
        [uiSref]="cmsReplace.linkAction"
        [uiParams]="cmsReplace.linkParams"
        [attr.aria-label]="cmsReplace.linkAccessibility"
        [innerHtml]="cmsReplace.linkName | safeHtml"
        [attr.target]="cmsReplace.linkTarget"
        [attr.banner]="cmsReplace.linkAnalytics">
    </a>
    <a *ngIf="cmsReplace.externalLink"
        [ngClass]="{'xm-btn-primary-light': cmsReplace.displayAsPrimary, 'xm-btn-secondary-light': cmsReplace.displayAsSecondary, 'xm-btn-link-light': cmsReplace.displayAsLink, 'xm-btn-link-light-bold': cmsReplace.displayAsBold, 'inline-text-replace': cmsReplace.useParentStyles, 'disclaimer-link': isDisclaimerLink, 'link-body-style': linkBodyStyle }"
        [href]="safeUrl(cmsReplace.linkAction)"
        [attr.aria-label]="cmsReplace.linkAccessibility"
        [innerHtml]="cmsReplace.linkName | safeHtml"
        target="{{ cmsReplace.linkTarget }}"
        [attr.banner]="cmsReplace.linkAnalytics">
    </a>
    <button *ngIf="cmsReplace.buttonLink" xmAriaFocus
        [ngClass]="{ 'xm-btn-primary-light': cmsReplace.displayAsPrimary, 'xm-btn-secondary-light': cmsReplace.displayAsSecondary, 'xm-btn-link-light': cmsReplace.displayAsLink, 'xm-btn-link-light-bold': cmsReplace.displayAsBold,'inline-text-replace': cmsReplace.useParentStyles, 'xm-btn-link-learnmore-medium': isLink, 'link-body-style': linkBodyStyle }"
        (click)="buttonClick($event)"
        [attr.aria-label]="cmsReplace.linkAccessibility"
        [innerHtml]="cmsReplace.linkName | safeHtml"
        [attr.banner]="cmsReplace.linkAnalytics">
    </button>
    <img *ngIf="enableArrow" alt="external link arrow" [src]="arrowLink" />
</span>
<span [xmAriaHidden]="isAriaHidden" [innerHtml]="afterText | safeHtml"></span>
<ng-content></ng-content>
