import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ModelBase } from 'store/model-base';
import { StoreAction } from 'store/actions';
import {
    PopularSearchCategory,
    SearchResult,
    SupportArticle,
    SupportCategoryCollection,
    SupportCategoryDetail,
    SupportSearchResult
} from './models';
import { buildHeaders, buildParams, Util, XmStore } from 'core/services';
import { ApiChannel } from 'core/constants';
import { InbentaSearchType } from 'src/xm/support/constants';
import { logAndHandleError } from 'services/log/LogHelper';
import { OperationType } from 'services/log/model/LogFields';

@Injectable()
export class SupportApi {
    public static getArticle(_xmStore: XmStore, http: HttpClient, params: ApiParams): Observable<SupportArticle> {
        return http.get(`/articles/article/data/ra-data/${params.articleId}`, { headers: buildHeaders({ apiChannel: ApiChannel.PROXY }) }).pipe(
            map((articleResponse: ApiResponse) => SupportArticle.create<SupportArticle>(articleResponse), 
                catchError(err => logAndHandleError(err, '/articles/article/data/ra-data/{params.articleId}', OperationType.GET)))
        );
    }

    public static getCategories(_xmStore: XmStore, http: HttpClient): Observable<SupportCategoryCollection> {
        return http.get('/articles/categories', { headers: buildHeaders({ apiChannel: ApiChannel.PROXY }) }).pipe(
            map((response: ApiResponse) => SupportCategoryCollection.create<SupportCategoryCollection>({ categories: response }), 
                catchError(err => logAndHandleError(err, '/articles/categories', OperationType.GET)))
        );
    }

    public static getSuggestionResults(_xmStore: XmStore, http: HttpClient, params: ApiParams): Observable<SearchResult> {
        return SupportApi.getInbenta(_xmStore, http, params, InbentaSearchType.SUGGESTION);
    }

    public static getPopularSearchCategory(_xmStore: XmStore, http: HttpClient): Observable<PopularSearchCategory> {
        return http.get('/support/popular_search_queries', {
            headers: buildHeaders({ apiChannel: ApiChannel.INBENTA }),
            params: {
                limit: '3'
            }
        }).pipe(
            map((response: ApiResponse) => PopularSearchCategory.create<PopularSearchCategory>(response), 
                catchError(err =>logAndHandleError(err, '/support/popular_search_queries', OperationType.GET)))
        );
    }

    public static getCategoryById(_xmStore: XmStore, http: HttpClient, params: ApiParams): Observable<SupportCategoryDetail> {
        return http.get(`/articles/categories/${params.categoryId}/details`, { headers: buildHeaders({ apiChannel: ApiChannel.PROXY }) }).pipe(
            map((response: ApiResponse) => {
                response.id = params.categoryId;

                return SupportCategoryDetail.create<SupportCategoryDetail>(response);
            }), 
            catchError(err => logAndHandleError(err, '/articles/categories/{params.categoryId}/details', OperationType.GET)) 
        );
    }

    public static getSearchResults(_xmStore: XmStore, http: HttpClient, params: ApiParams): Observable<SearchResult> {
        return SupportApi.getInbenta(_xmStore, http, params, InbentaSearchType.RESULT);
    }

    public static inbentaClick(_xmStore: XmStore, http: HttpClient, params: ApiParams): Observable<ApiResponse> {
        return http.post(`/support/click/${params.content_id}`, `click_callback_id=${encodeURIComponent(params.click_callback_id)}`, {
            headers: buildHeaders({ apiChannel: ApiChannel.INBENTA })
        }).pipe(catchError(err => logAndHandleError(err, '/support/click/{params.content_id}', OperationType.POST)));
    }

    private static getInbenta(_xmStore: XmStore, http: HttpClient, params: ApiParams, searchType: InbentaSearchType): Observable<SearchResult> {
        return http.get(`/support/${searchType === InbentaSearchType.SUGGESTION ? 'suggestions' : 'results'}`, {
            headers: buildHeaders({ apiChannel: ApiChannel.INBENTA }),
            params: buildParams({
                limit: 100,
                offset: 0,
                highlight: false,
                query: params.query
            })
        }).pipe(
            map((apiResponse: ApiResponse) => {
                const response: ApiResponse = apiResponse.response;
                const suggestion: ApiResponse = {
                    query: params.query,
                    type: searchType,
                    results: searchType === InbentaSearchType.SUGGESTION ? response.suggestions : response.results,
                    metadata: response.metadata
                };
                suggestion.results = suggestion.results.map((result: ApiResponse) => {
                    result.vanityTitle = Util.vanitize(result.title);

                    return SupportSearchResult.create<SupportSearchResult>(result);
                });

                return SearchResult.create<SearchResult>(suggestion);
            }), 
            catchError(err => logAndHandleError(err, `/support/${searchType === InbentaSearchType.SUGGESTION ? 'suggestions' : 'results'}`, OperationType.GET)) 
        );
    }
}

ModelBase.fetchMapping[StoreAction.GET_POPULAR_SEARCH_CATEGORY] = SupportApi.getPopularSearchCategory;
ModelBase.fetchMapping[StoreAction.GET_ARTICLE] = SupportApi.getArticle;
ModelBase.fetchMapping[StoreAction.GET_CATEGORIES] = SupportApi.getCategories;
ModelBase.fetchMapping[StoreAction.GET_CATEGORY_BY_ID] = SupportApi.getCategoryById;
ModelBase.fetchMapping[StoreAction.GET_SEARCH_RESULTS] = SupportApi.getSearchResults;
ModelBase.fetchMapping[StoreAction.GET_SUGGESTIONS] = SupportApi.getSuggestionResults;
ModelBase.fetchMapping[StoreAction.SEND_CLICK] = SupportApi.inbentaClick;
