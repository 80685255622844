import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { Util } from 'services/util';
import { IXMOptions } from 'core/interfaces';
import { ApiChannel, CONFIG_TOKEN } from 'core/constants';
import { CimaToken } from '../cima/token';
import { environment } from '../../../../environments/environment';

@Injectable()
export class ApiResourceInterceptor implements HttpInterceptor {
    private config: IXMOptions;
    private cimaToken: CimaToken;
    private inbentaSession: string = Util.generateUuid();
    private isProductionEnv: boolean = environment.production;
    private kibanaUrl: string;
    private kibanaKey: string;
    private cimaClientAuthWeb: string;

    constructor(cimaToken: CimaToken, @Inject(CONFIG_TOKEN) config: IXMOptions) {
        Object.assign(this, { cimaToken, config });
    }

    public intercept(request: HttpRequest<object>, next: HttpHandler): Observable<HttpEvent<object>> {
        let newRequest: HttpRequest<object> = request;
        if (!request.url.startsWith('/')) {
            const message: string = `partial url must start with a /, given \'${request.url}\'`;
            /* eslint-disable no-console */
            console.error(message);
            /* eslint-enable no-console */         

            return throwError(message);
        }

        if (this.isProductionEnv) {
            this.kibanaUrl = 'https://prod.core-gateway.api-business.comcast.com/logging-api';
            this.kibanaKey = 'CYgtuUmITK3BCCqq9U0Tx87ERCjYHL8YaYWVDxRr';
            this.cimaClientAuthWeb = 'Y2ItbW9iaWxlLXByb2QtY29tY2FzdC1jb206NDhlN2RjNmVjZWNkNDBmZmYyYjExNTFkYmUzNTBlNzAwNzM0ZTE5MA==';  
        } else {
            this.kibanaUrl = 'https://dev.core-gateway.np.api-business.comcast.com/logging-api';
            this.kibanaKey = 'cLmI91Zox71sObfWw5nL53X7kOPz9Rk6aCLJgHuD';
            this.cimaClientAuthWeb = 'Y2JtLWJ1c2luZXNzLWxvY2FsLWNvbWNhc3QtY29tOmMzNGJmZTRmMzVlNjRhM2M2YWNiYTVjZTJiZGI4Y2RjZTU1MjUyY2Y=';
        }

        const channelCode: string = request.headers.get('apiChannel');
        newRequest = newRequest.clone({ headers: request.headers.delete('apiChannel') }); // should not be used after this interceptor

        if (request.method !== 'GET') {
            newRequest = newRequest.clone({ setHeaders: { 'Content-Type': 'application/json' } });
        }

        switch (channelCode) {
            case ApiChannel.CIMA_WEB:
                newRequest = newRequest.clone({
                    url: `${this.config.CIMA_BASE_URL}${request.url}`,
                    setHeaders: { Authorization: `Basic ${this.cimaClientAuthWeb}` }
                });
                break;

            case ApiChannel.CMS:
                newRequest = newRequest.clone({ url: `${this.config.CMS_URL}${request.url}` });
                break;

            case ApiChannel.SITECORE_URL:
                if (this.config.SITECORE_URL) {
                    newRequest = this.generateCmsParams(newRequest.clone({
                        url: this.config.SITECORE_URL,
                        setParams: {
                            contentId: request.url.replace(/^\/*/, '')
                        }
                    }));
                } else {
                    newRequest = newRequest.clone({ url: `${this.config.CMS_URL}${request.url}` });
                }
                break;

            case ApiChannel.GATEWAY:
                newRequest = this.appendEpHeaders(newRequest.clone({
                    url: `${this.config.GATEWAY_BASE_URL}${request.url}`,
                    setHeaders: {
                        Authorization: `Bearer ${this.cimaToken.accessToken}`,
                        'X-Channel': 'WEB',
                        'X-global-tracking-id': Util.generateUuid()
                    }
                }));
                break;
            case ApiChannel.GATEWAY_FOR_GUID:
                newRequest = this.appendEpHeaders(newRequest.clone({
                    url: `${this.config.GATEWAY_BASE_URL}${request.url}`,
                    setHeaders: {
                        Authorization: `Bearer ${this.cimaToken.accessToken}`,
                        'X-Channel': 'WEB'
                    }
                }));
                break;

            case ApiChannel.PCAT:
                newRequest = this.appendEpHeaders(newRequest.clone({ url: `${this.config.PCAT_BASE_URL}${request.url}` }));
                break;

            case ApiChannel.PROXY:
                newRequest = newRequest.clone({ url: `${this.config.MSP_PROXY}${request.url}` });
                break;

            case ApiChannel.AKAMAI_PROXY:
                newRequest = this.appendAkamaiProxyHeader(newRequest.clone({ url: `${this.config.AKAMAI_PROXY}${request.url}` }));
                break;

            case ApiChannel.INBENTA:
                newRequest = newRequest.clone({
                    url: `${this.config.MSP_PROXY}${request.url}`,
                    setHeaders: {
                        'Content-Type': 'application/x-www-form-urlencoded'
                    },
                    setParams: {
                        session_id: this.inbentaSession
                    }
                });
                break;
            case ApiChannel.SESSION_API:
                newRequest = newRequest.clone({ url: `${this.config.VISITOR_SESSION_URL}${request.url}`,
                    setHeaders: {
                        'Content-Type': 'application/json' 
                    }
                });
                break;

            case ApiChannel.KIBANA_CODEBIG_API:
                newRequest = newRequest.clone({ url: `${this.kibanaUrl}${request.url}`,
                    setHeaders: {
                        'Content-Type': 'application/json',
                        'x-api-key': this.kibanaKey
                    }
                });
                break;

            case ApiChannel.ACCOUNT_REGISTRATION:
                newRequest = newRequest.clone({ url: `${this.config.ACCOUNT_URL}${request.url}`,
                    setHeaders: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${this.cimaToken.accessToken}`
                    }
                });
                break;

            default:
                const message: string = 'missing HTTP Header \'apiChannel\'';
                /* eslint-disable no-console */
                console.error(message);
                /* eslint-enable no-console */

                return throwError(message);
        }

        // everyone accepts JSON
        newRequest = newRequest.clone({ setHeaders: { Accept: 'application/json' } });

        return next.handle(newRequest);
    }

    private generateCmsParams(newRequest: HttpRequest<object>): HttpRequest<object> {
        if (this.config.CMS_CDSID && this.config.CMS_ENV) {
            return newRequest.clone({
                setParams: {
                    env: this.config.CMS_ENV,
                    cdsid: this.config.CMS_CDSID
                }
            });
        } else if (this.config.SITECORE_SITE) {
            return newRequest.clone({
                setParams: {
                    sc_site: this.config.SITECORE_SITE
                }
            });
        }

        return newRequest;
    }

    private appendEpHeaders(newRequest: HttpRequest<object>): HttpRequest<object> {
        if (this.config.CATALOG_SOURCE && this.config.CATALOG_SCOPE) {
            return newRequest.clone({
                setHeaders: {
                    'X-Catalog-Source': this.config.CATALOG_SOURCE,
                    'X-Catalog-Scope': this.config.CATALOG_SCOPE
                }
            });
        }

        return newRequest;
    }

    private appendAkamaiProxyHeader(newRequest: HttpRequest<object>): HttpRequest<object> {
        if (this.config.AKAMAI_PROXY_HEADER_VALUE) {
            return newRequest.clone({
                setHeaders: {
                    'XM-TRAFFIC': this.config.AKAMAI_PROXY_HEADER_VALUE
                }
            });
        }

        return newRequest;
    }
}
