import { ChildModel, ModelBase } from 'store/model-base';
import {ByodImeiCapability, PlanGroupType, PlanSku, PlanSubGroupType, PlanType } from 'core/constants';
import {
    PaymentPlan,
    PaymentPlanGroup,
    PlanCollection,
    PlanGroup,
    ProtectionPlan,
    ProtectionPlanGroup,
    RatePlan,
    RatePlanGroup
} from 'store/plan/models';
import { CarrierInfo } from 'buy/models/carrier-info';
import { RatePlanVariant } from 'store/plan/models/variant/rate';
import { PaymentPlanVariant } from 'store/plan/models/variant/payment';
import { ProtectionPlanVariant } from 'store/plan/models/variant/protection';

export class ProductDetail extends ModelBase {
    public iccid: string;
    public imei: string;
    public itemId: string;
    public lineId: string;
    public nickname: string;
    public portInfo: CarrierInfo;
    public skuType: string;

    public paymentPlanServiceId: string;
    public ratePlanServiceId: string;
    public protectionPlanServiceId: string;
    public lastFiveIccid: string;
    public selectedPlanId: string;
    public simType: string;


    public sharedDataVariants: RatePlanVariant[];
    public unlimitedVariants: RatePlanVariant[];
    public gigVariants: RatePlanVariant[];
    public rupVariants: RatePlanVariant[];
    public unlimitedIntroVariants: RatePlanVariant[];
    public unlimitedPlusVariants: RatePlanVariant[];
    public unlimitedPremiumVariants: RatePlanVariant[];
    public smartWatchVariants: RatePlanVariant[];
    public isUnlimitedIntro: boolean;
    public isUnlimitedPlus: boolean;
    public isUnlimitedPremium: boolean;


    public ratePlanVariant: RatePlanVariant;
    public paymentPlanVariant: PaymentPlanVariant;
    public payFullPlanVariant: PaymentPlanVariant;
    public financedPlanVariant: PaymentPlanVariant;
    public protectionPlanVariant: ProtectionPlanVariant;
    public xmppVariant: ProtectionPlanVariant;
    public servicePlanCollection: PlanCollection;
 

    /* eslint-disable @typescript-eslint/no-explicit-any */
    public static create<T extends ModelBase>(initData: ApiResponse): T {
        const toReturn: TransformedData = initData;
        toReturn.lastFiveIccid = toReturn.iccid && toReturn.iccid.substring(toReturn.iccid.length - 5);
        toReturn.servicePlanCollection = { plans: initData.servicePlans };

        if (!toReturn.servicePlans || !toReturn.servicePlans.length) {
            return super.create<T>(toReturn);
        }

        ProductDetail.createRatePlans(toReturn);
        ProductDetail.createPaymentPlans(toReturn);
        ProductDetail.createProtectionPlans(toReturn);

        return super.create<T>(toReturn);
    }
    /* eslint-enable @typescript-eslint/no-explicit-any */

    public get isEsimCapable(): boolean {
        if (this.simType === ByodImeiCapability.ESIM || this.simType === ByodImeiCapability.PSIM_ESIM) {
            return true;
        }
    }

    protected static get hasOne(): ChildModel[] {
        return [{
            attrName: 'paymentPlanVariant',
            model: PaymentPlanVariant
        }, {
            attrName: 'ratePlanVariant',
            model: RatePlanVariant
        }, {
            attrName: 'payFullPlanVariant',
            model: PaymentPlanVariant
        }, {
            attrName: 'financedPlanVariant',
            model: PaymentPlanVariant
        }, {
            attrName: 'protectionPlanVariant',
            model: ProtectionPlanVariant
        }, {
            attrName: 'portInfo',
            model: CarrierInfo
        }, {
            attrName: 'xmppVariant',
            model: ProtectionPlanVariant
        }, {
            attrName: 'servicePlanCollection',
            model: PlanCollection
        }];
    }

    protected static get hasMany(): ChildModel[] {
        return [{
            attrName: 'sharedDataVariants',
            model: RatePlanVariant
        }, {
            attrName: 'gigVariants',
            model: RatePlanVariant
        }, {
            attrName: 'unlimitedVariants',
            model: RatePlanVariant
        }, {
            attrName: 'smartWatchVariants',
            model: RatePlanVariant
        }, {
            attrName: 'rupVariants',
            model: RatePlanVariant
        }];
    }

    private static createRatePlans(toReturn: TransformedData): TransformedData {
        const ratePlanGroup: RatePlanGroup = toReturn.servicePlans.find((plan: PlanGroup) => plan.type === PlanType.RatePlan);

        if (ratePlanGroup) {
            const fullPlanId: string = ratePlanGroup.selectedPlanId;
            const planGroupId: string = fullPlanId.includes(':') ? fullPlanId.match(/[0-9]+?(?=:)/g)[0] : fullPlanId;
            const ratePlanOption: RatePlan = ratePlanGroup.options.find((ratePlan: RatePlan) => ratePlan.id === planGroupId);
            toReturn.ratePlanServiceId = ratePlanGroup.serviceId;
            toReturn.isUnlimitedIntro = ratePlanOption.planGroup === PlanGroupType.UNLIMITED && (ratePlanOption.planSubgroup === PlanSubGroupType.BASIC || ratePlanOption.planSubgroup === PlanSubGroupType.INTRO);
            toReturn.isUnlimitedPlus = ratePlanOption.planGroup === PlanGroupType.UNLIMITED && ratePlanOption.planSubgroup === PlanSubGroupType.PLUS;
            toReturn.isUnlimitedPremium = ratePlanOption.planGroup === PlanGroupType.UNLIMITED && ratePlanOption.planSubgroup === PlanSubGroupType.PREMIUM;
            toReturn.ratePlanVariant = ratePlanOption && ratePlanOption.variants.find((variant: RatePlanVariant) => variant.id === fullPlanId);
            if (!toReturn.ratePlanVariant) {
                toReturn.ratePlanVariant = ratePlanOption && ratePlanOption.variants.find((variant: RatePlanVariant) => variant.planId === ratePlanGroup.selectedPlanId);
            }

            // edit cart options
            const sharedData: RatePlan = ratePlanGroup.options.find((ratePlan: RatePlan) => ratePlan.planGroup === PlanGroupType.BY_THE_GIG_SHARED);
            const unlimited: RatePlan = ratePlanGroup.options.find((ratePlan: RatePlan) => ratePlan.planGroup === PlanGroupType.UNLIMITED);
            const smartWatch: RatePlan = ratePlanGroup.options.find((ratePlan: RatePlan) => ratePlan.planGroup === PlanGroupType.SMART_WATCH);

            toReturn.sharedDataVariants = sharedData ? sharedData.variants : [];
            toReturn.unlimitedVariants = unlimited ? unlimited.variants : [];
            toReturn.smartWatchVariants = smartWatch ? smartWatch.variants : [];
            toReturn.selectedPlanId = fullPlanId;
        }

        return toReturn;
    }

    private static createPaymentPlans(toReturn: TransformedData): TransformedData {
        const paymentPlanGroup: PaymentPlanGroup = toReturn.servicePlans.find((plan: PlanGroup) => plan.type === PlanType.PaymentPlan);
        let paymentPlanOption: PaymentPlan = paymentPlanGroup ? paymentPlanGroup.options.find((paymentPlan: PaymentPlan) => paymentPlan.id === paymentPlanGroup.selectedPlanId) : undefined;
        toReturn.paymentPlanServiceId = paymentPlanGroup ? paymentPlanGroup.serviceId : '';
        toReturn.paymentPlanVariant = paymentPlanOption ? paymentPlanOption.variants.find((variant: PaymentPlanVariant) => variant.id === paymentPlanGroup.selectedPlanId) : undefined;

        paymentPlanOption = paymentPlanGroup ? paymentPlanGroup.options.find((paymentPlan: PaymentPlan) => paymentPlan.id === PlanSku.FINANCED) : undefined;
        toReturn.financedPlanVariant = paymentPlanOption ? paymentPlanOption.variants.find((variant: PaymentPlanVariant) => variant.id === PlanSku.FINANCED) : undefined;

        paymentPlanOption = paymentPlanGroup ? paymentPlanGroup.options.find((paymentPlan: PaymentPlan) => paymentPlan.id === PlanSku.PAY_IN_FULL) : undefined;
        toReturn.payFullPlanVariant = paymentPlanOption ? paymentPlanOption.variants.find((variant: PaymentPlanVariant) => variant.id === PlanSku.PAY_IN_FULL) : undefined;

        return toReturn;
    }

    private static createProtectionPlans(toReturn: TransformedData): TransformedData {
        const protectionPlanGroup: ProtectionPlanGroup = toReturn.servicePlans.find((plan: PlanGroup) => plan.type === PlanType.ProtectionPlan);
        const protectionPlanOption: ProtectionPlan = protectionPlanGroup ? protectionPlanGroup.options.find((protectionPlan: ProtectionPlan) => protectionPlan.id !== PlanSku.NO_XMPP) : undefined;
        toReturn.protectionPlanServiceId = protectionPlanGroup ? protectionPlanGroup.serviceId : '';
        toReturn.protectionPlanVariant = protectionPlanOption ? protectionPlanOption.variants.find((variant: ProtectionPlanVariant) => variant.id === protectionPlanGroup.selectedPlanId) : undefined;

        // xmpp variant option for edit item
        toReturn.xmppVariant = protectionPlanOption ? protectionPlanOption.variants[0] : undefined;

        return toReturn;
    }

    public get isItemPorting(): boolean {
        return this.portInfo && this.portInfo.isPort;
    }
}
