import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { OverlayModule } from '@angular/cdk/overlay';
import { StoreModule } from '@ngrx/store';
import { Angular2PromiseButtonModule } from 'angular2-promise-buttons';
import { SWIPER_CONFIG, SwiperConfigInterface } from 'ngx-swiper-wrapper';

import * as Api from './store/apis';
import * as Service from './services';
import { CoreRoute } from './route';
import { metaReducers, reducers } from './store/reducers';
import { BUTTON_LOADER_CLASS, CONFIG_TOKEN, REWRITE_TOKEN } from './constants';

import { ModalModule } from 'shared/modal/module';
import { IXMOptions } from './interfaces';
import { Util } from './services/util';
import { ApiResourceInterceptor } from 'services/http/resource';
import { ShareRequestInterceptor } from 'services/http/share';
import { TimeoutInterceptor } from 'services/http/timeout';
import { GenericErrorInterceptor } from 'services/http/generic-error';
import { CmsErrorInterceptor } from 'services/http/cms-error';
import { Analytics } from './services';

export function initRouterListeners(analytics: Analytics, coreRoute: CoreRoute): () => void {
    return () => {
        analytics.setupRouterListener();
        coreRoute.init();
    };
}

export function redirectOrReplaceUrl(options: IXMOptions, rewriteRules: CmsRewriteRules, seo: Service.Seo): () => Promise<void> {
    return () => new Promise((resolve: () => void) => {
        const baseUrl: string = `${location.protocol}//${location.host}${options.BASE_URL}`;
        let currentUrl: string = location.href.replace(baseUrl, '');
        if (currentUrl.endsWith('/')) {
            const updatedUrl = currentUrl.substring(0, currentUrl.lastIndexOf('/'));
            window.location.href = updatedUrl;
        }
        let isExternalUrl: boolean;
        let trackingParam: string;
        const querySearchRegex: string = '([?].+)?';

        const newURLFound: boolean = rewriteRules.rewriteRules.rules.reduce((replace: boolean, rule: RewriteRule) => {

            if (rule.type === 'regex' && rule.replaceUrl) {
                rule.searchValue = rule.searchValue.endsWith('$') ? `${rule.searchValue.split('$')[0]}${querySearchRegex}$` : `${rule.searchValue}${querySearchRegex}`;
            }

            const regex: RegExp = new RegExp(rule.type === 'string' ? Util.escapeRegExp(rule.searchValue) : rule.searchValue);
            const matchFound: boolean = regex.test(currentUrl);

            if (matchFound) {
                if (rule.replaceUrl) {
                    isExternalUrl = true;
                    trackingParam = window.location.search.substring(1);
                }
                currentUrl = isExternalUrl ? rule.replaceUrl : currentUrl.replace(regex, rule.replaceValue);
            }

            return replace || matchFound;
        }, false);

        if (newURLFound) {
            let fullUrl: string = isExternalUrl ? `${currentUrl}` : `${baseUrl}${currentUrl}`;
            fullUrl = trackingParam ? fullUrl.includes('?') ? `${fullUrl}&${trackingParam}` : `${fullUrl}?${trackingParam}` : fullUrl;

            if (isExternalUrl) {
                if (!window.navigator.userAgent.includes('Prerender')) {
                    window.location.replace(fullUrl);
                }

                // do not resolve otherwise to block booting the app
            } else {
                window.history.replaceState(undefined, undefined, fullUrl);
                resolve();
            }

            seo.addPrerender301Tag(fullUrl);
        } else {
            resolve();
        }
    });
}

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
    init: false, // by default we prevent swiper from initializing...the components must be explicit
    a11y: true,
    speed: 500
};

@NgModule({
    imports: [
        Angular2PromiseButtonModule.forRoot({
            disableBtn: false,
            btnLoadingClass: BUTTON_LOADER_CLASS,
            spinnerTpl: '<span class="btn-loader"><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div></span>'
        }),
        HttpClientModule,
        StoreModule.forRoot(reducers, {
            metaReducers,
            runtimeChecks: {
                // turning these off for now since they are blocking a lot of buyflow
                // TODO: will discuss how we can turn them back in the future
                strictStateImmutability: false,
                strictActionImmutability: false
            }
        }),
        ModalModule,
        OverlayModule
    ],
    providers: [
        Api.CallbackApi,
        Api.CreditCheckApi,
        Api.CartApi,
        Api.CmsApi,
        Api.CreditCardApi,
        Api.HopApi,
        Api.NdelApi,
        Api.ByodApi,
        Api.OrderApi,
        Api.PastDueApi,
        Api.PlanApi,
        Api.ProductApi,
        Api.SupportApi,
        Api.UserApi,
        Api.WatchesApi,
        Api.XmbApi,
        CoreRoute,
        {
            provide: SWIPER_CONFIG,
            useValue: DEFAULT_SWIPER_CONFIG
        },
        {
            provide: ErrorHandler,
            useClass: Service.XmErrorHandler
        },
        {
            provide: APP_INITIALIZER,
            useFactory: redirectOrReplaceUrl,
            deps: [ CONFIG_TOKEN, REWRITE_TOKEN, Service.Seo ],
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initRouterListeners,
            deps: [ Analytics, CoreRoute ],
            multi: true
        },
        { provide: HTTP_INTERCEPTORS, useClass: ApiResourceInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ShareRequestInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: GenericErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: CmsErrorInterceptor, multi: true }
    ]
})
export class CoreModule {}
