import { StoreAction, XmAction } from 'store/actions';
import { CustomizeItem } from './models';

export function customizeItemReducer(state: CustomizeItem, action: XmAction<CustomizeItem>): CustomizeItem {
    switch (action.type) {
        case StoreAction.SAVE_CUSTOMIZE_ITEM:
            return action.payload;
        case StoreAction.CLEAR_CUSTOMIZE_ITEM:
            return undefined;
        default:
            return state;
    }
}
