import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, first, map, mergeMap, take } from 'rxjs/operators';

import { LocalDB } from 'store/local-db';
import { ModelBase } from 'store/model-base';
import { Util } from './util';
import { IXMOptions } from 'core/interfaces';
import { CONFIG_TOKEN } from 'core/constants';

export class StoreUndefined {}

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-throw-literal */
@Injectable({
    providedIn: 'root'
})
export class XmStore {
    private config: IXMOptions;
    private store: Store<LocalDB>;
    private http: HttpClient;

    constructor(@Inject(CONFIG_TOKEN) config: IXMOptions, store: Store<LocalDB>, http: HttpClient) {
        Object.assign(this, { config, store, http });
    }

    /**
     * Search the store for data based on the key and value.
     *
     * Types:
     * - "T": class name for the model to find
     *
     * Params:
     * - "model": the model to search for (similar to the DB table)
     * - "filters": the object of key/value pairs to match on. all pairs must match to be considered a complete match. the keys can be a chain of nesting properties.
     * - "throwError": check if an error is thrown or undefined is returned
     *
     * Ex.
     * {
     *     "id": "1234",
     *     "id.key": "5678"
     * }
     *
     * Return: if found, return the model  ... otherwise undefined
     */
    public peek<T>(model: typeof ModelBase, options: StoreFilterOptions = {}): Observable<T> {
        let obs: Observable<T> = this.store.select((state: LocalDB) => state[model.storeName]).pipe(
            map((storeData: T[]|T) => {
                let toReturn: T;

                if (storeData) {
                    // 1. check the data is an Array and ensure 1 or more filters are passed
                    // 2. check the data is not an Array and ensure no filters were passed
                    if (Array.isArray(storeData) && Object.keys(options.filters || {}).length > 0) {
                        toReturn = storeData.find((data: T) => {
                            const keys: string[] = Object.keys(options.filters);

                            return keys.every((key: string) => Util.dotWalk(data, key) === options.filters[key]);
                        });
                    } else if (!Array.isArray(storeData) && !options.filters) {
                        toReturn = storeData;
                    }
                }

                if (toReturn) {
                    return <T> <any> (new model(toReturn));
                } else {
                    if (options.returnUndefined) {
                        return undefined;
                    } else {
                        this.logPeekUndefined(model, options.filters || {});
                        throw new StoreUndefined();
                    }
                }
            })
        );

        if (options.firstOnly) {
            obs = obs.pipe(first());
        }

        return obs;
    }

    public peekPromise<T>(model: typeof ModelBase, options?: StoreFilterOptions): Promise<T> {
        return this.peek<T>(model, options).pipe(take(1)).toPromise();
    }

    public peekMany<T>(model: typeof ModelBase, options: StoreFilterOptions = {}): Observable<T[]> {
        let obs: Observable<T[]> = this.store.select((state: LocalDB) => state[model.storeName]).pipe(
            map((storeData: T[]) => {
                let toReturn: T[];

                if (storeData) {
                    if (Array.isArray(storeData)) {
                        if (Object.keys(options.filters || {}).length > 0) {
                            toReturn = storeData.filter((data: T) => {
                                const keys: string[] = Object.keys(options.filters);

                                return keys.every((key: string) => Util.dotWalk(data, key) === options.filters[key]);
                            });
                        } else {
                            toReturn = storeData;
                        }
                    }
                }

                if (toReturn) {
                    return toReturn.map((data: T) => <T> <any> (new model(data)));
                } else {
                    if (options.returnUndefined) {
                        return undefined;
                    } else {
                        this.logPeekUndefined(model, options.filters || {});
                        throw new StoreUndefined();
                    }
                }
            })
        );

        if (options.firstOnly) {
            obs = obs.pipe(first());
        }

        return obs;
    }

    public peekManyPromise<T>(model: typeof ModelBase, options?: StoreFilterOptions): Promise<T[]> {
        return this.peekMany<T>(model, options).pipe(take(1)).toPromise();
    }

    /**
     * Search the store for a child model nested within another model
     *
     * Types:
     * - "C": class name for the child model to find
     * - "P": class name for the root model to start the search from
     *
     * Params:
     * - "childKey": the property name to search for on the modal class
     * - "child": class name for the child model to find
     * - "parent": class name for the root model to start the search from
     *
     * Return: if found, a copy of the child model ... otherwise undefined
     */
    public peekChild<C extends ModelBase, P extends ModelBase>(childKey: string, child: typeof ModelBase, parent: typeof ModelBase, options: StoreBaseOptions = {}): Observable<C> {
        let obs: Observable<C> = this.store.select((state: LocalDB) => state[parent.storeName]).pipe(
            map((storeData: P) => {
                let toReturn: C;

                if (storeData) {
                    const matchedModel: C = storeData.deepLookupOne<C>(childKey);

                    if (Util.dotWalk(matchedModel, child.idAttribute) !== undefined) {
                        toReturn = <C> <any> (new child(matchedModel));
                    }
                }

                if (toReturn) {
                    return toReturn;
                } else {
                    if (options.returnUndefined) {
                        return undefined;
                    }

                    this.logPeekChildUndefined(childKey, child, parent);
                    throw new StoreUndefined();
                }
            })
        );

        if (options.firstOnly) {
            obs = obs.pipe(first());
        }

        return obs;
    }

    public peekChildPromise<C extends ModelBase, P extends ModelBase>(childKey: string, child: typeof ModelBase, parent: typeof ModelBase, options?: StoreBaseOptions): Promise<C> {
        return this.peekChild<C, P>(childKey, child, parent, options).pipe(take(1)).toPromise();
    }

    /**
     * Search the store for a collection of child models nested within another model
     *
     * Types:
     * - "C": class name for the child model to find
     * - "P": class name for the root model to start the search from
     *
     * Params:
     * - "childKey": the property name to search for on the modal class
     * - "child": class name for the child model to find
     * - "parent": class name for the root model to start the search from
     *
     * Return: if found, a copy of the child models ... otherwise undefined
     */
    public peekChildMany<C extends ModelBase, P extends ModelBase>(childKey: string, child: typeof ModelBase, parent: typeof ModelBase, options: StoreChildFilterOptions = {}): Observable<C> {
        let obs: Observable<C> = this.store.select((state: LocalDB) => state[parent.storeName]).pipe(
            map((storeData: P) => {
                let toReturn: C;

                if (storeData) {
                    const matchedModel: C = storeData.deepLookupMany<C>(childKey, options.childFilters || []);
                    if (matchedModel) {
                        toReturn = <C> <any> new child(matchedModel);
                    }

                    return matchedModel ? <C> <any> new child(matchedModel) : matchedModel;
                }

                if (toReturn) {
                    return toReturn;
                } else {
                    if (options.returnUndefined) {
                        return undefined;
                    }

                    this.logPeekChildUndefined(childKey, child, parent);
                    throw new StoreUndefined();
                }
            })
        );

        if (options.firstOnly) {
            obs = obs.pipe(first());
        }

        return obs;
    }

    public peekChildManyPromise<C extends ModelBase, P extends ModelBase>(childKey: string, child: typeof ModelBase, parent: typeof ModelBase, options?: StoreChildFilterOptions): Promise<C> {
        return this.peekChildMany<C, P>(childKey, child, parent, options).pipe(take(1)).toPromise();
    }

    /**
     * Fetch the data from an API without saving it to the store
     *
     * Types:
     * - "T": the model to create from the API response
     *
     * Params:
     * - "action": value that maps to an API call
     * - "model": the model to create from the API response
     * - "param": body to send along to the API
     */
    public query<T>(action: string, param: number | string | object = ''): Observable<T> {
        return (ModelBase.fetchMapping[action] || ModelBase.persistMapping[action])(this, this.http, param, this.config).pipe(
            map((data: T) => data),
            catchError((error: any) => {
                this.logThrownError(error);
                throw error;
            })
        );
    }

    public queryPromise<T>(action: string, param: number | string | object = ''): Promise<T> {
        return this.query<T>(action, param).toPromise();
    }

    /**
     * Fetch the data from an API and save it into the store
     *
     * Types:
     * - "T": the model to create from the API response
     *
     * Params:
     * - "action": value that maps to an API call
     * - "model": the model to create from the API response
     * - "filters": the object of key/value pairs to match on. all pairs must match to be considered a complete match. the keys can be a chain of nesting properties.
     *
     * Ex.
     * {
     *     "id": "1234",
     *     "id.key": "5678"
     * }
     */
    public fetch<T>(action: string, options: StoreFetchOptions = {}): Observable<T> {
        return ModelBase.fetchMapping[action](this, this.http, options.params || {}, this.config).pipe(
            map((data: T) => {
                if (data) {
                    this.store.dispatch({ payload: data, type: action, filters: options.filters, params: options.params });
                }

                return data;
            }),
            catchError((error: any) => {
                this.logThrownError(error);
                throw error;
            })
        );
    }

    public fetchPromise<T>(action: string, options?: StoreFetchOptions): Promise<T> {
        return this.fetch<T>(action, options).toPromise();
    }

    /**
     * First check the store if the data exists. Only if the data does not exist, make an API call to retrieve it.
     *
     * Types:
     * - "T": the model to create from the API response
     *
     * Params:
     * - "action": value that maps to an API call
     * - "model": the model to create from the API response
     * - "filters": the object of key/value pairs to match on. all pairs must match to be considered a complete match. the keys can be a chain of nesting properties.
     *
     * Ex.
     * {
     *     "id": "1234",
     *     "id.key": "5678"
     * }
     */
    public find<T>(action: string, model: typeof ModelBase, options: StoreFullOptions = {}): Observable<T> {
        // for the first call, we need to override the option
        return this.peek<T>(model, {
            returnUndefined: true,
            firstOnly: options.firstOnly,
            filters: options.filters
        }).pipe(
            take(1),
            mergeMap((peeked: T) => {
                if (peeked) {
                    return of(peeked);
                }

                return this.fetch<T>(action, {
                    params: options.params,
                    filters: options.filters
                });
            })
        );
    }

    public findPromise<T>(action: string, model: typeof ModelBase, options?: StoreFullOptions): Promise<T> {
        return this.find<T>(action, model, options).toPromise();
    }

    /**
     * Search the store for a child model nested within another model. If not found, call an API to retrieve the data.
     *
     * Types:
     * - "C": class name for the child model to find
     * - "P": class name for the root model to start the search from
     *
     * Params:
     * - "action": value that maps to an API call
     * - "childKey": the property name to search for on the model class
     * - "child": class name for the child model to find
     * - "parent": class name for the root model to start the search from
     */
    public findChild<C extends ModelBase, P extends ModelBase>(action: string, childKey: string, child: typeof ModelBase, parent: typeof ModelBase): Observable<C> {
        return this.peekChild<C, P>(childKey, child, parent, { returnUndefined: true }).pipe(
            take(1),
            mergeMap((peeked: C) => {
                if (peeked) {
                    return of(peeked);
                }

                return this.fetch<P>(action).pipe(
                    mergeMap(() => this.peekChild<C, P>(childKey, child, parent).pipe(take(1)))
                );
            })
        );
    }

    public findChildPromise<C extends ModelBase, P extends ModelBase>(action: string, childKey: string, child: typeof ModelBase, parent: typeof ModelBase): Promise<C> {
        return this.findChild<C, P>(action, childKey, child, parent).toPromise();
    }

    /**
     * Search the store for a collection of child models nested within another model. If not found, call an API to retrieve the data.
     *
     * Types:
     * - "C": class name for the child model to find
     * - "P": class name for the root model to start the search from
     *
     * Params:
     * - "action": value that maps to an API call
     * - "childKey": the property name to search for on the modal class
     * - "child": class name for the child model to find
     * - "parent": class name for the root model to start the search from
     */
    public findChildMany<C extends ModelBase, P extends ModelBase>(action: string, childKey: string, child: typeof ModelBase, parent: typeof ModelBase, options: StoreFullOptions = {}): Observable<C> {
        return this.peekChildMany<C, P>(childKey, child, parent, {
            childFilters: options.childFilters,
            firstOnly: options.firstOnly,
            filters: options.filters
        }).pipe(
            take(1),
            mergeMap((peeked: C) => {
                if (peeked) {
                    return of(peeked);
                }

                return this.fetch<P>(action, { params: options.params, filters: options.filters }).pipe(
                    mergeMap(() => this.peekChildMany<C, P>(childKey, child, parent).pipe(take(1)))
                );
            })
        );
    }

    public findChildManyPromise<C extends ModelBase, P extends ModelBase>(action: string, childKey: string, child: typeof ModelBase, parent: typeof ModelBase, options?: StoreFullOptions): Promise<C> {
        return this.findChildMany<C, P>(action, childKey, child, parent, options).toPromise();
    }

    /**
     * Given the action, we post data to the API and only on a successful response will we save the data to the store
     *
     * Types:
     * - "T": class name for the return type model
     *
     * Params:
     * - "model": the model of the data being passed in
     * - "action": value that maps to an API call
     * - "payload": payload to send to the API
     */
    public persist<T>(action: string, payload?: object, options: StorePersistOptions = {}): Promise<T> {
        // if an api hook exist use that otherwise save directly into the store
        if (ModelBase.persistMapping[action]) {
            return ModelBase.persistMapping[action](this, this.http, payload, { ...options }, this.config).pipe(
                map((data: T) => {
                    if (data) {
                        this.store.dispatch({ type: action, payload: data });
                    }

                    return data;
                }),
                catchError((error: any) => {
                    this.logThrownError(error);
                    throw error;
                })).toPromise();
        } else {
            this.store.dispatch({ type: action, payload });

            return <Promise<T>> <any> Promise.resolve(payload);
        }
    }

    /**
     * Given the action, we post data (optional) to the API and only on a successful response will we delete the data to the store
     *
     * Types:
     * - "T": class name for the return type model
     *
     * Params:
     * - "model": the model of the data being passed in
     * - "action": value that maps to an API call
     * - "payload": payload to send to the API
     */
    public delete<T>(action: string, payload?: object, options: StorePersistOptions = {}): Promise<T> {
        if (ModelBase.deleteMapping[action]) {
            return ModelBase.deleteMapping[action](this, this.http, payload, { ...options, storeAction: action }, this.config).pipe(
                map((data: T) => {
                    if (data) {
                        this.store.dispatch({ type: action, payload: data });
                    }

                    return data;
                }),
                catchError((error: any) => {
                    this.logThrownError(error);
                    throw error;
                })).toPromise();
        } else {
            this.store.dispatch({ type: action, payload, filters: options.filters });

            return <Promise<T>> <any> Promise.resolve(payload);
        }
    }

    // clear multiple apis/models from store only
    public clearApis(apiActions: StoreDeleteAction[]): void {
        apiActions.forEach((apiAction: StoreDeleteAction) => {
            this.delete(apiAction.action, undefined, apiAction.filters);
        });
    }

    /* eslint-disable no-console */
    private logThrownError(error: any): void {
        if (!this.config.DISABLE_DEV_TOOLS) {
            console.log(error);
        }
    }

    private logPeekUndefined(model: typeof ModelBase, filters: StoreFilters): void {
        if (!this.config.DISABLE_DEV_TOOLS) {
            console.log('StoreUndefined');
            console.log('Model:', model.name);
            console.log('Filters:', filters);
        }
    }

    private logPeekChildUndefined(childKey: string, child: typeof ModelBase, parent: typeof ModelBase): void {
        if (!this.config.DISABLE_DEV_TOOLS) {
            console.log('StoreUndefined');
            console.log('Child:', childKey, child.name);
            console.log('Parent:', parent.name);
        }
    }
    /* eslint-enable no-console */
}

export class XmStoreUtil {
    /* eslint-disable no-empty, @typescript-eslint/no-empty-function */
    public static subscribe<T>(observe: Observable<T>, success: (value: T) => void, error: (error: any) => void = () => {}, complete: () => void = () => {}): Subscription {
        return observe.subscribe(success, error, complete);
    }
    /* eslint-enable no-empty, @typescript-eslint/no-empty-function */

    public static defaultCatch<T>(promise: Promise<T>): Promise<T> {
        return promise.catch(() => Promise.resolve(undefined));
    }
}
/* eslint-enable @typescript-eslint/no-throw-literal */
/* eslint-enable @typescript-eslint/no-explicit-any */
