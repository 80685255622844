import { ModelBase } from 'store/model-base';

export class PromoEligibilityServices extends ModelBase {
    public static storeName: string = 'promoEligibilityServices';

    public portIn: boolean;
    public newNum: boolean;
    public btg: boolean;
    public unl: boolean;
    public dpp: boolean;
    public xmpp: boolean;
    public byod: boolean;

    // tip: Trade-in promo. Doesn't consider D2C at the moment
    public tip: boolean;
}
