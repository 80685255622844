import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { fromEvent, Subscription } from 'rxjs';
import * as MobileDetect from 'mobile-detect';

import { Cloudinary, Util, XmStore, XmStoreUtil } from 'core/services';
import { CmsCore } from 'store/cms/models/core';
import { KeyboardEventKeys } from 'core/constants';

const KEYBOARD_SEARCH_INTERVAL: number = 300;

@Component({
    selector: 'xm-dropdown',
    styleUrls: [ './dropdown.scss' ],
    templateUrl: './dropdown.html',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: XmDropdownComponent,
        multi: true
    }]
})
export class XmDropdownComponent implements OnInit, OnDestroy, AfterViewInit, ControlValueAccessor {
    @Input() public optionsList: DropdownItem[];
    @Input() public placeholder: string;
    @Input() public ariaLabelId: string;
    @Input() public ariaDescribedBy: string;
    @Input() public scrollHeight: string;
    @Input() public whiteCaret: boolean;
    @Input() public isInModal: boolean = false;
    @Input() public customClass: string = '';
    @Input() public caretImage: MediaImageOptions;
    @Input() public clearAllEmitter: EventEmitter<undefined>;
    @Output() public onCollapse: EventEmitter<DropdownOutput> = new EventEmitter<DropdownOutput>();

    @ViewChild('dropdownRoot') public root: ElementRef;
    @ViewChild('listElement') public listElement: ElementRef;
    @ViewChild('nativeSelect') public selectElement: ElementRef;
    @ViewChild('button') public buttonElement: ElementRef;
    @ViewChildren('itemElement') public itemElements: QueryList<ElementRef>;

    public placeholderItem: DropdownItem;
    public selectionElem: ElementRef;
    public buttonAttrId: string;
    public buttonAttrAriaLabel: string;
    public listStyle: CssStyles;
    public selectStyle: CssStyles;
    public expanded: boolean;
    public selection: DropdownItem;
    public caretImageOptions: MediaImageOptions;
    public isMobileOrTablet: boolean;
    public ariaActiveDescendId: string;

    private mobileDetect: MobileDetect = new MobileDetect(navigator.userAgent);
    private formCtrlOnTouch: Function;
    private formCtrlOnChange: Function;
    private inputString: string = '';
    private isDirty: boolean = false;
    private touched: boolean = false;
    private selectionIndex: number;
    private timeout: number = 0;
    private xmStore: XmStore;
    private subscriptions: Subscription[] = [];

    constructor(xmStore: XmStore) {
        Object.assign(this, { xmStore });
    }

    public ngOnInit(): void {
        if (!this.ariaLabelId) {
            throw new Error('Please give \'ariaLabelId\' to xm-dropdown');
        }

        this.isMobileOrTablet = Boolean(this.mobileDetect.mobile() || this.mobileDetect.tablet());

        this.buttonAttrId = `${this.ariaLabelId}_dropdown_button`;
        this.buttonAttrAriaLabel = `${this.ariaLabelId} ${this.buttonAttrId}`;
        this.listStyle = { 'max-height': this.scrollHeight };

        if (this.placeholder) {
            this.placeholderItem = {
                label: this.placeholder,
                value: 'placeholder',
                selected: true
            };
        }

        this.subscriptions.push(XmStoreUtil.subscribe(this.xmStore.peek<CmsCore>(CmsCore), (response: CmsCore) => {
            this.caretImageOptions = this.whiteCaret ? {
                mobile: Cloudinary.generateMediaFromCms(response.icons.whiteCaret)
            } : {
                mobile: Cloudinary.generateMediaFromCms(response.icons.caretIcon)
            };
        }));
        if (this.clearAllEmitter) {
            this.subscriptions.push(this.clearAllEmitter.subscribe(() => {
                const clearedOptionsList = this.optionsList.map((option, i) => {
                    option.selected = i === 0 ? true : false;
                    
                    return option;
                });
                this.optionsList = clearedOptionsList;
                this.selection = this.optionsList[0];
                this.selectionIndex = this.optionsList.indexOf(this.selection);
            }));
        }
        this.selection = this.optionsList.find((item: DropdownItem) => item.selected) || this.placeholderItem || this.optionsList[0];
        this.selectionIndex = this.optionsList.indexOf(this.selection);
    }

    public ngOnDestroy(): void {
        Util.unsubscribeAll(this.subscriptions);
    }

    public ngAfterViewInit(): void {
        if (this.isMobileOrTablet) {
            this.subscriptions.push(fromEvent(this.selectElement.nativeElement, 'change').subscribe(this.nativeSelectChange.bind(this)));
        } else {
            this.subscriptions.push(fromEvent(this.root.nativeElement, 'keydown').subscribe(this.keyDown.bind(this)));
        }
    }

    // ControlValueAccessor interface function
    public writeValue(itemValue: string): void {
        if (itemValue) {
            const writeValueIndex: number = this.optionsList.findIndex((item: DropdownItem) => item.value === itemValue);
            this.select(writeValueIndex);
        }
    }

    // ControlValueAccessor interface function
    public registerOnChange(callBack: Function): void {
        if (callBack) {
            this.formCtrlOnChange = callBack;
        }
    }

    // ControlValueAccessor interface function
    public registerOnTouched(callBack: Function): void {
        if (callBack) {
            this.formCtrlOnTouch = callBack;
        }
    }

    public toggle(focusButton: boolean = true): void {
        this.expanded = !this.expanded;

        if (!this.expanded) {
            this.ariaActiveDescendId = undefined;

            if (this.isDirty) {
                this.triggerChangeEvents();
            }

            if (focusButton) {
                setTimeout(() => {
                    this.buttonElement.nativeElement.focus();
                });
            }
        }
    }

    public onOptionClick(item: DropdownItem): void {
        if (!item.isDecorator) {
            this.select(this.optionsList.indexOf(item));
            this.toggle();
        }
    }

    public buttonOrListBlur(event: FocusEvent): void {
        // Support for IE
        const relatedTargetElement: EventTarget = document.activeElement === document.body ? event.relatedTarget : document.activeElement;

        if (!this.isMobileOrTablet && this.expanded && (!relatedTargetElement || !this.root.nativeElement.contains(relatedTargetElement))) {
            this.toggle(false);
        }

        if (this.formCtrlOnTouch) {
            this.touched = true;
            this.formCtrlOnTouch({ touched: this.touched, isDirty: this.isDirty });
        }
    }

    private select(index: number): void {
        if (index !== this.selectionIndex) {
            const item: DropdownItem = this.optionsList[index];
            this.selection.selected = false;
            item.selected = true;
            this.selection = item;
            this.selectionIndex = index;
            this.isDirty = true;
        }
    }

    private triggerChangeEvents(): void {
        this.onCollapse.emit({
            selection: this.selection,
            selectionIndex: this.selectionIndex,
            optionList: this.optionsList
        });
        if (this.formCtrlOnChange) {
            this.formCtrlOnChange(this.selection.value);
        }
    }

    private setFocusOnSelection(): void {
        this.ariaActiveDescendId = `${this.selection.label}${this.selectionIndex}`;
        const currentElement: ElementRef = this.itemElements.toArray()[this.selectionIndex];
        currentElement.nativeElement.focus();
    }

    private nativeSelectChange(): void {
        let index: number = this.selectElement.nativeElement.selectedIndex;
        if (this.placeholderItem) {
            index -= 1;
        }
        this.select(index);
        this.triggerChangeEvents();
    }

    private keyDown(event: KeyboardEvent): void {
        const preventDefault: boolean = this.expanded ? this.expandedKeyDown(event) : this.collapsedKeyDown(event);

        if (preventDefault) {
            event.preventDefault();
        }
    }

    private collapsedKeyDown(event: KeyboardEvent): boolean {
        switch (true) {
            case event.key === KeyboardEventKeys.ARROW_UP || (!event.ctrlKey && !event.shiftKey && !event.altKey && event.key === KeyboardEventKeys.ARROW_DOWN):
                this.toggle();
                this.expandedKeyDown(event);
                break;
            case event.key === KeyboardEventKeys.ENTER || event.key === KeyboardEventKeys.SPACE || (event.altKey && event.key === KeyboardEventKeys.ARROW_DOWN):
                this.toggle();
                break;
            default:
                return false;
        }

        return true;
    }

    private expandedKeyDown(event: KeyboardEvent): boolean {
        switch (true) {
            case event.key === KeyboardEventKeys.ARROW_DOWN && this.selectionIndex < this.optionsList.length - 1:
                const targetIndex: number = this.buttonElement.nativeElement === document.activeElement && this.expanded ? this.selectionIndex : this.selectionIndex + 1;
                this.arrowDown(targetIndex);
                break;
            case event.key === KeyboardEventKeys.ARROW_UP && this.selectionIndex > 0:
                this.arrowUp(this.selectionIndex - 1);
                break;
            case event.key === KeyboardEventKeys.ENTER || event.key === KeyboardEventKeys.SPACE || event.key === KeyboardEventKeys.ESCAPE:
                this.toggle();
                break;
            case event.key.length === 1:
                clearTimeout(this.timeout);
                this.inputString += event.key.toLowerCase();
                this.timeout = window.setTimeout(() => {
                    this.searchByKeys(this.inputString);
                    this.inputString = '';
                }, KEYBOARD_SEARCH_INTERVAL);
                break;
            case event.key === KeyboardEventKeys.HOME:
                this.targetUp(0);
                break;
            case event.key === KeyboardEventKeys.END:
                this.targetDown(this.optionsList.length - 1);
                break;
            default:
                return false;
        }

        return true;
    }

    private searchByKeys(inputString: string): void {
        const arrayToFindNext: DropdownItem[] = this.selectionIndex > -1
            ? this.optionsList.slice(this.selectionIndex + 1).concat(this.optionsList.slice(0, this.selectionIndex))
            : this.optionsList;

        const searchResult: DropdownItem = arrayToFindNext.find((item: DropdownItem) => item.label.toLowerCase().startsWith(inputString) && !item.isDecorator);

        if (!searchResult) {
            return;
        }
        const resultIndex: number = this.optionsList.indexOf(searchResult);
        if (resultIndex > this.selectionIndex) {
            this.targetDown(resultIndex);
        } else {
            this.targetUp(resultIndex);
        }
    }

    private arrowDown(targetIndex: number): void {
        if (targetIndex < 0 || this.optionsList[targetIndex].isDecorator) {
            this.arrowDown(targetIndex + 1);
        } else {
            this.targetDown(targetIndex);
        }
    }

    private arrowUp(targetIndex: number): void {
        if (this.optionsList[targetIndex].isDecorator) {
            this.arrowUp(targetIndex - 1);
        } else {
            this.targetUp(targetIndex);
        }
    }

    /* eslint-disable @typescript-eslint/restrict-plus-operands */
    private targetDown(targetIndex: number): void {
        this.select(targetIndex);
        setTimeout(() => {
            this.setFocusOnSelection();
            if (!this.scrollHeight) {
                return;
            }
            const selectionElement: ElementRef = this.itemElements.toArray()[this.selectionIndex];
            const scrollTop: number = selectionElement.nativeElement.offsetTop - this.listElement.nativeElement.clientHeight
                + selectionElement.nativeElement.offsetHeight + (selectionElement.nativeElement.nextSibling ? selectionElement.nativeElement.nextSibling.offsetHeight : 0);
            if (scrollTop > this.listElement.nativeElement.scrollTop && scrollTop <= this.listElement.nativeElement.scrollHeight - this.listElement.nativeElement.clientHeight) {
                this.listElement.nativeElement.scrollTop = scrollTop;
            }
        });
    }

    private targetUp(targetIndex: number): void {
        this.select(targetIndex);
        setTimeout(() => {
            this.setFocusOnSelection();
            if (!this.scrollHeight) {
                return;
            }
            const selectionElement: ElementRef = this.itemElements.toArray()[this.selectionIndex];
            const scrollTop: number = selectionElement.nativeElement.offsetTop - (selectionElement.nativeElement.previousSibling ? selectionElement.nativeElement.previousSibling.offsetHeight : 0);
            if (scrollTop < this.listElement.nativeElement.scrollTop) {
                this.listElement.nativeElement.scrollTop = scrollTop;
            }
        });
    }
    /* eslint-enable @typescript-eslint/restrict-plus-operands */
}
