import { Injectable } from '@angular/core';
import { HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { concatMap, filter, finalize, share, tap } from 'rxjs/operators';
import { LogTimeFields } from 'services/log/model/LogFields';
import { getOperationType, logAndHandleMessage } from 'services/log/LogHelper';

interface ActiveRequest {
    url: string;
    observable: Observable<HttpEvent<object>>;
}

interface ChainRequest {
    action: string;
    observables: Observable<HttpEvent<object>>[];
    sharedObservable: Observable<HttpEvent<object>>;
}

@Injectable({
    providedIn: 'root'
})
export class ActiveRequests {
    private chainedRequests: ChainRequest[] = [];
    private activeRequests: ActiveRequest[] = [];

    /****************************
     * Active Request Functions *
     ****************************/

    public hasActiveRequest(url: string): boolean {
        return this.activeRequests.some((req: ActiveRequest) => req.url === url);
    }

    public getActiveRequest(url: string): ActiveRequest {
        return this.activeRequests.find((req: ActiveRequest) => req.url === url);
    }   

    public setActiveRequest(url: string, observable: Observable<HttpEvent<object>>, shareRequest: boolean = false, sessionID: string, globalTrackingId: string, requestMethod: string): Observable<HttpEvent<object>> {
        const startTimestamp = new Date().toISOString();

        let fullObservable: Observable<HttpEvent<object>> = observable.pipe(
           
            finalize(() => this.deleteActiveRequest(url)),
            tap({
                complete: () => {
                    const endTimestamp = new Date().toISOString();
                    const logTimeFields: LogTimeFields = {
                        StartTimeUtc: startTimestamp,
                        EndTimeUtc: endTimestamp                
                    };

                    if ((!url.includes('logging-api') && !url.includes('json'))) {  
                        logAndHandleMessage({sessionId: sessionID, globalTrackingId: globalTrackingId}, url, getOperationType(requestMethod), logTimeFields);
                    }
                }
            })
        );

        // share must come last in the pipe chain
        if (shareRequest) {
            fullObservable = fullObservable.pipe(share());
        }

        this.activeRequests.push({ url, observable: fullObservable });

        return fullObservable;
    }

    /*****************************
     * Chained Request Functions *
     *****************************/

    public hasChainedRequest(action: string): boolean {
        return this.chainedRequests.some((request: ChainRequest) => request.action === action);
    }

    public getChainedRequest(action: string): ChainRequest {
        return this.chainedRequests.find((request: ChainRequest) => request.action === action);
    }

    public addChainedRequest(action: string, observable: Observable<HttpEvent<object>>, shareChain: boolean = false): Observable<HttpEvent<object>> {
        const existingRequest: ChainRequest = this.getChainedRequest(action);
        if (existingRequest) {
            const fullObservable: Observable<HttpEvent<object>> = observable.pipe(
                filter((event: HttpEvent<object>) => event instanceof HttpResponse),
                concatMap((val: HttpEvent<object>) => this.processNextObservable(val, existingRequest))
            );

            existingRequest.observables.push(fullObservable);

            return existingRequest.sharedObservable;
        } else {
            const newRequest: ChainRequest = {
                action,
                observables: [],
                sharedObservable: undefined
            };

            let fullObservable: Observable<HttpEvent<object>> = observable.pipe(
                filter((event: HttpEvent<object>) => event instanceof HttpResponse),
                concatMap((val: HttpEvent<object>) => this.processNextObservable(val, newRequest)),
                finalize(() => this.deleteChainedRequest(action))
            );

            // share must come last in the pipe chain
            if (shareChain) {
                fullObservable = fullObservable.pipe(share());
            }

            newRequest.sharedObservable = fullObservable;
            this.chainedRequests.push(newRequest);

            return newRequest.sharedObservable;
        }
    }

    private deleteActiveRequest(url: string): void {
        const index: number = this.activeRequests.findIndex((req: ActiveRequest) => req.url === url);
        if (index >= 0) {
            this.activeRequests.splice(index, 1);
        }
    }

    private processNextObservable(val: HttpEvent<object>, originalRequest: ChainRequest): Observable<HttpEvent<object>> {
        return originalRequest.observables.length ? originalRequest.observables.splice(0, 1)[0] : of(val);
    }

    private deleteChainedRequest(action: string): void {
        const index: number = this.chainedRequests.findIndex((req: ChainRequest) => req.action === action);
        if (index >= 0) {
            this.chainedRequests.splice(index, 1);
        }
    }
}
