import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { catchError, map, mergeMap, take } from 'rxjs/operators';

import { CreditCheck } from './models';
import { StoreAction } from 'store/actions';
import { ApiChannel } from 'core/constants';
import { buildHeaders, XmStore } from 'core/services';
import { ModelBase } from '../model-base';
import { BuyInfo, User } from 'store/user/models';
import { logAndHandleError } from 'services/log/LogHelper';
import { OperationType } from 'services/log/model/LogFields';

@Injectable()
export class CreditCheckApi {
    public static getCreditCheck(xmStore: XmStore, http: HttpClient, params: ApiParams): Observable<CreditCheck> {

        return xmStore.peekChild<BuyInfo, User>('buyInfo', BuyInfo, User).pipe(
            mergeMap((buyInfo: BuyInfo) => http.get('/account/creditcheck', {
                headers: buildHeaders({
                    apiChannel: ApiChannel.GATEWAY,
                    sessionID: buyInfo.sessionId,
                    liabilityType: params.liabilityType ? params.liabilityType : undefined
                }),
                params: {
                    runECC: params.runECC || false
                }
            }).pipe(
                map((creditcheck: CreditCheck) => CreditCheck.create<CreditCheck>(creditcheck)), 
                catchError(err => logAndHandleError(err, '/account/creditcheck', OperationType.GET)))
            ),
            take(1)
        );
    }
}

ModelBase.fetchMapping[StoreAction.GET_CREDIT_CHECK] = CreditCheckApi.getCreditCheck;
