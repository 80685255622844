import { ModelBase } from 'store/model-base';
import { Availability, BUY_CHANNEL, OS, ShopCategory } from 'core/constants';

export class Product extends ModelBase {
    public static idAttribute: string = 'slug';

    public brand: string;
    public brandCode: string;
    public category: string;
    public colors: ProductColor[];
    public dateAdded: Date;
    public deviceOS: string;
    public name: string;
    public productCode: string;
    public rank: number;
    public slug: string;
    public subcategory: string;
    public availableChannels: BUY_CHANNEL[];
    public is5GCapable: boolean;
    public status: Availability;

    public get displayName(): string {
        return this.brand && this.name ? `${this.brand} ${this.name}` : '';
    }

    public get isAccessory(): boolean {
        return this?.category?.toUpperCase() === ShopCategory.ACCESSORY;
    }

    public get isDevice(): boolean {
        return this?.category?.toUpperCase() === ShopCategory.DEVICE;
    }

    public get isTablet(): boolean {
        return this?.category?.toUpperCase() === ShopCategory.TABLET;
    }

    public get isAndroid(): boolean {
        return this?.deviceOS && this?.deviceOS?.toUpperCase() === OS.ANDROID;
    }

    public get isWatch(): boolean {
        return this?.category?.toUpperCase() === ShopCategory.WATCH;
    }

    public get notAvailableOnline(): boolean {
        return Boolean(this.availableChannels && !this.availableChannels.includes(BUY_CHANNEL.ONLINE));
    }

    public get productStateName(): string {
        if (this.isDevice) {
            return 'shop.device';
        } else if (this.isTablet) {
            return 'shop.tablet';
        } else if (this.isWatch) {
            return 'shop.watch';
        } else if (this.isAccessory) {
            return 'shop.accessory';
        }
    }
}
