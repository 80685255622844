import { PlanSku, XMPP_NAME } from 'core/constants';
import { PlanVariant } from './plan';

export class ProtectionPlanVariant extends PlanVariant {
    public get isXMPP(): boolean {
        return this.id && this.id !== PlanSku.NO_XMPP;
    }

    public get monthlyPrice(): number {
        return this.isXMPP ? this.price.monthlyCharge : 0;
    }

    public get cartInfo(): string {
        return `${XMPP_NAME}, ${this.displayPrice}`;
    }
}
