import { ChildModel, ModelBase } from 'store/model-base';
import { PaymentInstrument } from './payment/instrument';
import { StreetAddress } from 'store/user/models';
import { AccountType, BuyInfoAccountType, IdentityCheck, MBCMessageCodes } from 'core/constants';

export class BuyInfo extends ModelBase {
    public static idAttribute: string = 'sessionId';

    public firstName: string;
    public lastName: string;
    public displayName: string;
    public accountId: Id;
    public accountType: BuyInfoAccountType;
    public customerOver18: boolean;
    public creditCheckOffer: CreditCheckOffer;
    public identityInfoProvided: IdentityCheck;
    public paymentInstrument: PaymentInstrument;
    public nextCycleDate: string;
    public customerVerificationMethod: string;
    public messages: Message[];
    public channel: Channel;
    public customerProfileUrl: string;
    public homeUrl: string;
    public sessionId: string;
    public eligibility: boolean;
    public existingModestoCustomer: boolean;
    public ineligibilityReasons: EligibilityReason[];
    public tps: string;
    public level: AccountType;
    public currentSharedPlanId: string;
    public isBusinessAccount: boolean;
    public isIdentityInfoCleared: boolean;
    public businessEligible: boolean;
    public isSegmentTwo: boolean;
    public isSegmentThree: boolean;
    public liabilityType: string;
    public isSegmentFour: boolean;

    /* eslint-disable @typescript-eslint/no-explicit-any */
    public static create<T extends ModelBase>(initData: ApiResponse): T {
        const toReturn: TransformedData = initData;
        toReturn.displayName = toReturn.firstName && toReturn.lastName && `${toReturn.firstName} ${toReturn.lastName}`;
        toReturn.isIdentityInfoCleared = toReturn.identityInfoProvided === IdentityCheck.CLEARED;
        toReturn.isBusinessAccount = toReturn.accountType === BuyInfoAccountType.BUSINESS;
        toReturn.businessEligible = toReturn.eligibility || toReturn.existingModestoCustomer;
        toReturn.isSegmentTwo = toReturn.level === AccountType.LEVEL_2;
        toReturn.isSegmentThree = toReturn.level === AccountType.LEVEL_3;
        toReturn.isSegmentFour = toReturn.level === AccountType.LEVEL_4;

        return super.create<T>(toReturn);
    }

    
    /* eslint-enable @typescript-eslint/no-explicit-any */

    protected static get hasOne(): ChildModel[] {
        return [{
            attrName: 'paymentInstrument',
            model: PaymentInstrument
        }];
    }

    public get billingName(): string {
        return this.paymentInstrument.card.billingAddress.fullName;
    }

    public get billingAddress(): StreetAddress {
        return this.paymentInstrument.card.billingAddress.address;
    }

    public hasMessage(code: string): boolean {
        return Boolean(this.messages && this.messages.find((message: Message) => message.code === code));
    }

    public get isGrandfathered(): boolean {
        return this.level === AccountType.LEVEL_1;
    }

    public get canBuyPifNdel(): boolean {
        return Boolean(this.messages && this.messages.find((message: Message) => message.code === MBCMessageCodes.CAN_BUY_PIF_NDEL));
    }
}
