import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { CONFIG_TOKEN } from 'core/constants';
import { IXMOptions } from 'core/interfaces';

@Component({
    selector: 'cms-text',
    styleUrls: [ './text.scss' ],
    templateUrl: './text.html'
})
export class CmsTextComponent implements OnInit, OnChanges {
    @Input() public originalText: string;
    @Input() public analyticsText: string;
    @Input() public isLink: boolean = false;
    @Input() public linkBodyStyle?: boolean;
    @Input() public isDisclaimerLink?: boolean = false;
    @Input() public enableArrow?: boolean = false;
    @Output() public onButtonClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

    public afterText: string = '';
    public isAriaHidden: boolean = false;
    public cmsReplaces: CmsLinkReplace[] = [];
    public arrowLink: string;

    private sanitizer: DomSanitizer;
    private config: IXMOptions;

    constructor(@Inject(CONFIG_TOKEN) config: IXMOptions, sanitizer: DomSanitizer) {
        Object.assign(this, { config, sanitizer });
    }

    public ngOnInit(): void {
        this.generatedLinkFromCms(this.originalText, this.analyticsText);
        this.arrowLink = `${this.config.BUY_STATIC_BASE_URL}/images/external_arrow_icon.svg`;
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && (changes.originalText && !changes.originalText.firstChange || changes.analyticsText && !changes.analyticsText.firstChange)) {
            this.cmsReplaces = [];
            this.afterText = '';
            this.generatedLinkFromCms(this.originalText, this.analyticsText);
        }
    }

    public buttonClick(event: MouseEvent): void {
        this.onButtonClick.emit(event);
    }

    public safeUrl(url: string): SafeUrl {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    /**
     * Takes inline text with an action and converts to the respective <span>, <a> or <button>
     *
     * @param text the original text to find and replace with links and/or bold text
     * @param functionName the name of the function to link with (NOTE: only works if original text has one link to replace)
     *
     *
     * Examples:
     *
     * URL LINKS
     *
     * Link Text and Open in New Tab:
     * [<Click Here>] => <a class="xm-btn-link-light" target="_blank">Click Here</a>
     *
     * Link Text and Full URL Path:
     * [Click Here|www.google.com]  =>  <a class="xm-btn-link-light" href="www.googe.com" target="_blank">Click Here</a>
     *
     * Link Text, Aria Text and Link Action:
     * [Click Here#Click here to open google|www.google.com]  =>  <a class="xm-btn-link-light" href="www.googe.com" target="_blank" aria-label="Click here to open google">Click Here</a>
     *
     * Link Text and State Name:
     * [Click Here|state.name]  =>  <a class="xm-btn-link-light" uiSref="state.name">Click Here</a>
     *
     * Link Text and State Name and new Tab:
     * [_Click Here|state.name_]  =>  <a class="xm-btn-link-light" uiSref="state.name">Click Here</a>
     *
     * Link Text, State Name, and State Params:
     * [Click Here|state.name|'brand': 'Apple']  =>  <a class="xm-btn-link-light" uiSref="state.name" [uiParams]="{ 'brand': 'Apple' }">Click Here</a>
     *
     * Link Text and State Name LOOKS AS Primary Button:
     * [$$Click Here|state.name$$]  =>  <a class="xm-btn-primary-light" uiSref="state.name">Click Here</a>
     *
     * Link Text and State Name LOOKS AS Secondary Button:
     * [$Click Here|state.name$]  =>  <a class="xm-btn-secondary-light" uiSref="state.name">Click Here</a>
     *
     * Link Text LOOKS AS Parent styles:
     * [^Click Here|www.google.com^]  =>  <a class="xm-btn-link-light inline-text-replace" href="www.googe.com" target="_blank">Click Here</a>
     *
     * Link Text with State Name and Anchor Tag:
     * [Click Here|state.name|'#': 'tag-name'] => <a uiSref="state.name" href="state/name#tag-name"></a>
     *
     *
     * BUTTONS
     *
     * Link Text and Button:
     * [Click Here]  =>  <button class="xm-btn-link-light" (click)="buttonClick()">Click Here</button>
     *
     * Link Text, Aria Text and Button:
     * [Click Here#Click here to open google]  =>  <button class="xm-btn-link-light" (click)="buttonClick()" aria-label="Click here to open google">Click Here</button>
     *
     * Link Text and Button LOOKS AS Primary Button:
     * [$$Click Here$$]  =>  <button class="xm-btn-primary-light" (click)="buttonClick()">Click Here</button>
     *
     * Link Text and Button LOOKS AS SECONDARY Button:
     * [$Click Here$]  =>  <button class="xm-btn-secondary-light" (click)="buttonClick()">Click Here</button>
     *
     * Link Text LOOKS AS Parent styles:
     * [^Click Here^]  =>  <a class="xm-btn-link-light inline-text-repalce" uiSref="state.name">Click Here</a>
     *
     */
    private generatedLinkFromCms(text: string, analyticsText?: string): void {
        if (!text) {
            return;
        }

        // CMS markdown to bold text
        // Ex. **Text** -> <span class="bold">Text</span>
        let generatedText: string = text.replace(/\*\*(.+?)\*\*/g, '<span class="bold">$1</span>');

        const matches: RegExpMatchArray = generatedText.match(/\[.+?\]/g);

        if (matches && matches.length > 0) {
            matches.forEach((match: string) => {
                const newCmsReplace: CmsLinkReplace = {};

                // variable to check whether state url to be opened in a new tab or not
                let isNewTab: boolean;

                // split the text by match and get the before text
                newCmsReplace.beforeText = generatedText.split(match, 1)[0];

                // reassign the after text so we can continue to loop over all replacements
                generatedText = generatedText.substring(newCmsReplace.beforeText.length + match.length);

                // remove the starting '[ ' and ending ' ]'
                let insideMatch: string = match.match(/\[(.+?)\]/)[1];

                // determine what type of link this is
                if (insideMatch.startsWith('$$') && insideMatch.endsWith('$$')) {
                    newCmsReplace.displayAsPrimary = true;
                    insideMatch = insideMatch.substr(2, insideMatch.length - 4);
                } else if (insideMatch.startsWith('$') && insideMatch.endsWith('$')) {
                    newCmsReplace.displayAsSecondary = true;
                    insideMatch = insideMatch.substr(1, insideMatch.length - 2);
                } else if (insideMatch.startsWith('$$$')) {
                    newCmsReplace.displayAsBold = true;
                    insideMatch = insideMatch.substr(3, insideMatch.length - 2);
                } else {
                    newCmsReplace.displayAsLink = true;
                }

                // determine if the link should use parent styles
                if (insideMatch.startsWith('^') && insideMatch.endsWith('^')) {
                    newCmsReplace.useParentStyles = true;
                    insideMatch = insideMatch.substr(1, insideMatch.length - 2);
                }

                // determine if the state url link to be opened in a new tab
                if (insideMatch.startsWith('<') && insideMatch.endsWith('>')) {
                    isNewTab = true;
                    insideMatch = insideMatch.substr(1, insideMatch.length - 2);
                }

                // separate the text from the action
                const data: string[] = insideMatch.split('|');
                [ newCmsReplace.linkName, newCmsReplace.linkAction ] = insideMatch.split('|');
                [ newCmsReplace.linkName, newCmsReplace.linkAccessibility ] = newCmsReplace.linkName.split('#');

                if (newCmsReplace.linkAction && (this.absoluteUrl(newCmsReplace.linkAction) || this.httpProtocol(newCmsReplace.linkAction))) {
                    newCmsReplace.linkTarget = this.absoluteUrl(newCmsReplace.linkAction) ? '_blank' : '_self';
                    newCmsReplace.externalLink = true;
                } else if (newCmsReplace.linkAction) {
                    newCmsReplace.internalLink = true;
                    newCmsReplace.linkTarget = isNewTab ? '_blank' : '_self';
                } else {
                    newCmsReplace.buttonLink = true;
                }

                // let's see if they passed UI-Params. If so, attach curly braces
                newCmsReplace.linkParams = data.length === 3 ? JSON.parse((`{${data[2]}}`).replace(/\'/g, '"')) : '';

                // Need to set aria hidden for subsequent span if it contains same text
                if (newCmsReplace.linkAccessibility && newCmsReplace.linkAccessibility.includes(text.replace(/\.$/g, ''))) {
                    this.isAriaHidden = true;
                }

                // for analytics
                newCmsReplace.linkAnalytics = analyticsText && newCmsReplace.linkName ? analyticsText + newCmsReplace.linkName : undefined;

                this.cmsReplaces.push(newCmsReplace);
            });
        }

        this.afterText = generatedText;
    }

    private absoluteUrl(link: string): boolean {
        return link.startsWith('www.') || link.startsWith('http') || link.startsWith('/');
    }

    private httpProtocol(link: string): boolean {
        return link.startsWith('tel:') || link.startsWith('sms:') || link.startsWith('mailto:');
    }
}
