export enum OperationType {
    GET= 'GET',
    POST= 'POST',
    UPDATE= 'UPDATE',
    DELETE= 'DELETE'
}

export enum LogType {
    SA= 'SA',
    MESSAGE= 'Message'
}


export interface LogProperties {
    message?: string;
    errorType?: string;
    operationType?: OperationType;
    baseUrl?: string;
    api?: string;
    request?: {}; 
    response?: {}; 
    status?: string; 
    statusCode?: string;
    severity?: string;
    properties?: {};
    logTimeFields?: LogTimeFields; 
}

export interface LogFields {
    ApplicationId?: string;
    TrackingId?: string;   
    Level?: string;
    AppType?: string;
    LogType?: LogType;
    Message?: string;
    Severity?: string;
    Environment?: string;
    BaseUrl?: string;
    CreateDateUtc?: string;
    OperationType?: string;
    Request?: {}; 
    Response?: {};
    Status?: string; 
    StatusCode?: string;
    Properties?: {};
    StartTimeUtc?: string;
    EndTimeUtc?: string;
}

export interface LogTimeFields {
    StartTimeUtc: string;
    EndTimeUtc: string;
}

export enum LogLevel {
    error= 'Error',
    info= 'Information',
    message= 'Message'
}