import { ModelBase } from 'store/model-base';
import { CreditCheckSource, CreditCheckStatus, MBCMessageCodes } from 'core/constants';

export class CreditCheck extends ModelBase {
    public static storeName: string = 'creditCheck';

    public approvedFinanceAmount: number;
    public accountType: string;
    public allowedFinancedLines: number;
    public allowedNewLines: number;
    public creditCheckStatus: CreditCheckStatus;
    public source: CreditCheckSource;
    public negotiable: CreditCheckItemId[];
    public unrestricted: CreditCheckItemId[];
    public messages: Message[];
    public allMessages: Message[];

    public tps: string;
    public liabilityType: string;
    public isPlEccEligible: boolean; 

    public isForce: boolean;
    public isPartial: boolean;
    public isPartialILC: boolean;
    public isPartialECC: boolean;
    public isHardStop: boolean;
    public isPassed: boolean;
    public isUnavailable: boolean;
    public isDownPaymentAmountChanged: boolean;
    public isStubOffer: boolean;
    public creditCheckOffer: CreditCheckOffer;

    public static create<T extends ModelBase>(data: ApiResponse): T {
        data.negotiable = data.negotiable || [] || data.messages.some((message: Message) => message.code === MBCMessageCodes.NEGOTIABLE_ITEM);
        data.unrestricted = data.unrestricted || [];
        data.accountType = data.accountType || '';
        data.liabilityType = data.liabilityType || '';
        data.isPlEccEligible = data.isPlEccEligible || false;
        data.isStubOffer = data.isStubOffer || false;

        data.messages = data.messages || [];
        data.allMessages = data.messages || [];
        data.messages = data.messages.filter((message: Message) => message.code !== MBCMessageCodes.CREDIT_CHECK_DONE); // Meanst the ECC was run, we don't care
        data.isForce = data.messages.some((message: Message) => message.code === MBCMessageCodes.FORCE_ECC);
        data.isPartial = data.messages.some((message: Message) => message.code === MBCMessageCodes.ILC_PARTIAL_SUCCESS || message.code === MBCMessageCodes.ECC_PARTIAL_SUCCESS);
        data.isPartialILC = data.messages.some((message: Message) => message.code === MBCMessageCodes.ILC_PARTIAL_SUCCESS);
        data.isPartialECC = data.messages.some((message: Message) => message.code === MBCMessageCodes.ECC_PARTIAL_SUCCESS || message.code === MBCMessageCodes.NEGOTIABLE_ITEM);
        data.isHardStop = data.messages.some((message: Message) => message.code === MBCMessageCodes.CREDIT_CHECK_HARD_STOP);
        data.isDownPaymentAmountChanged = data.messages.some((message: Message) => message.code === MBCMessageCodes.DOWNPAYMENT_AMOUNT_CHANGE);
        data.isPassed = !data.messages.length;
        data.isUnavailable = data.messages.some((message: Message) => message.code === MBCMessageCodes.CREDIT_CHECK_UNAVAILABLE);
        data.allowedFinancedLines = data.allowedFinancedLines > 0 ? data.allowedFinancedLines : 0;

        return super.create<T>(data);
    }

    public get isILC(): boolean {
        return this.source === CreditCheckSource.ILC;
    }

    public get isECC(): boolean {
        return this.source === CreditCheckSource.ECC;
    }

    public get ilcCount(): number {
        return this.isILC ? this.creditCheckOffer.ilc.allowedFinancedLines : 0;
    }

    public get eccCount(): number {
        return this.isECC ? (this.creditCheckOffer.selectedOffer === 'BL' ? this.creditCheckOffer.blEcc.allowedFinancedLines : this.creditCheckOffer.plEcc.allowedFinancedLines) : 0;
    }

    public exceedingNewLineLimit(cartNewLine: number): boolean {
        return this.allowedNewLines > 0 && this.allowedNewLines < cartNewLine;
    }
}
