import { ModelBase } from 'store/model-base';
import { PromotionType } from 'core/constants';

export class OrderItemPromotion extends ModelBase {
    public type: PromotionType;
    public value: number;
    public valueTotal: number;
    public percentValue: number;
    public promoDescription: string;
    public id: string;
    public isAmount: boolean;
    public isPercentage: boolean;
    public isPrepaid: boolean;
    public isExtendedPromo: boolean;
    public isExtendedPromoDollar: boolean;
    public isExtendedPromoPercentage: boolean;

    public static create<T extends ModelBase>(data: ApiResponse = {}): T {
        data.isAmount = data.type === PromotionType.DOLLAR_AMOUNT;
        data.isPercentage = data.type === PromotionType.PERCENTAGE;
        data.isPrepaid = data.type === PromotionType.GIFT_CARD;
        data.isExtendedPromo = data.type === PromotionType.EXTENDED_PROMO;
        data.isExtendedPromoDollar = data.type === PromotionType.EXTENDED_PROMO_DOLLAR;
        data.isExtendedPromoPercentage = data.type === PromotionType.EXTENDED_PROMO_PCT;

        return super.create<T>(data);
    }
}
