import { IS_CBM_CUSTOMER } from 'core/constants';

export enum PAGE_LANGUAGE {
    english = 'en',
    spanish = 'es'
}

export const DEFAULT_PAGE: TDDPage = {
    attributes: {
        isPageLoadVariables: true
    },
    category: {
        pageType: '',
        primaryCategory: '',
        subCategory: ''
    },
    pageInfo: {
        language: window.location.hostname === 'es.xfinity.com' ? PAGE_LANGUAGE.spanish : PAGE_LANGUAGE.english,
        pageLoadEvent: 'prodLoad',
        pageName: ''
    }
};

export enum CUSTOMER_TYPE {
    new = 'New',
    existing = 'Existing',
    unknown = 'Unknown',
    tester = 'Tester'
}

export enum ACCOUNT_TYPE {
    main = 'Main',
    sub = 'Sub'
}

export enum AUTHENTICATION_STATUS {
    authenticated = 'Authenticated',
    unauthenticated = 'Unauthenticated'
}

export const SECONDARY_USER: string = 'secondary-user';

export const DEFAULT_SITE_INFO: TDDSiteInfo = {
    server: 'CBM-MOBILE',
    sessionId: '',
    siteType: 'sales',
    siteVersion: 'Responsive',
    visitorId: ''
};

export const DEFAULT_USERS: TDDUser[] = [
    {
        profile: [
            {
                profileInfo: {
                    authenticationStatus: AUTHENTICATION_STATUS.unauthenticated,
                    customerType: CUSTOMER_TYPE.new,
                    accountType: '',
                    userGUID: '',
                    custGUID: '',
                    segmentLevel: '',
                    isCBMCustomer: IS_CBM_CUSTOMER.NO
                }
            }
        ]
    }
];

export const DEFAULT_CART: TDDCart = {
    attributes: {
        buyflowstep: '',
        buyflowtype: '',
        derailReason: '',
        numberOfAccessories: 0, 
        numberOfLines: 0, 
        totalItems: 0,
        totalPrice: 0,
        transactionID: ''
    },
    cartId: '',
    products: {
        productInfo: []
    }
};

export const DTM_SEND_EVENT: string = 'send-Event';
export const LINE_OF_BUSINESS: string = 'Comcast Business Mobile';
export const CUSTOM_CLICK_EVENT: string = 'click';
export const CUSTOM_BUTTON_CLICK_EVENT: string = 'button click';
export const ID_IDENTIFER: string = 'key.';

export enum SimledPreActivationPageNames {
    READY_TO_ACTIVATE = 'preactivation|ready-to-activate',
    ACTIVATE_LATER = 'preactivation|activate-later'
}

export enum SimledPreActivationErrors {
    LINES_TIMEOUT = '228|lines api errored or timeout',
    ACTIVATION_TIMEOUT = '994|activation api errored or timeout'
}

export enum CustomizerPhone {
    BUYFLOW_STEP = 'Phone Customizer',
    BUTTON_TYPE = 'Click Continue Customizer'
}

export enum CustomizerAccessory {
    BUYFLOW_STEP = 'Accessory Customizer',
    BUTTON_TYPE = 'Click Continue Customizer'
}

export enum ConfirmationAttributes {
    BUYFLOW_STEP = 'confirmation',
    BUYFLOW_TYPE = 'cbm buyflow'
}

export enum CheckoutAttributes {
    BUYFLOW_STEP = 'checkout',
    BUYFLOW_TYPE = 'cbm buyflow'
}

/**
 * A blacklist of states for which we need to prevent the
 * analytics page view tracking call during transition.
 *
 * Being used in the onload() function of the analytics service.
 */
export const PREVENT_PAGEVIEW_ROUTES: string[] = [
    'checkout.eligibility',
    'shop.device',
    'shop.accessory',
    'shop.tablet',
    'shop.watch',
    'shop.customize'
];

export const DEFAULT_EVENT: TDigitalEvent[] = [];

/**
 * Events should be inserted upon requirements with basic structure like:
 *
 * events: [
 *      {
 *          eventName: '', // should be same as eventName to be fired.
 *          name: 'event name',
 *          action: 'event action',
 *          page: 'event pipified page route',
 *          dtmEvent: '' // If required - Direct Call rule for DTM Tracking
 *       }
 * ]
 */
export const CUSTOM_EVENTS: TDDEvents = {
    events: [
        {
            eventName: 'General Search',
            name: 'button click',
            action: 'Search',
            dtmEvent: 'custom-SupportSearch'
        },
        {
            eventName: 'device-selection',
            name: 'button click',
            action: 'Galaxy Selection',
            dtmEvent: 'galaxyClick'
        },
        {
            eventName: 'carrier-selection',
            name: 'button click',
            action: 'Carrier Selection',
            dtmEvent: 'carrierClick'
        },
        {
            eventName: 'support-search-autocomplete',
            name: 'button click',
            action: 'Search',
            dtmEvent: 'custom-SupportSearch'
        },
        {
            eventName: 'Login',
            action: 'Login',
            dtmEvent: 'loginEvent'
        },
        {
            eventName: 'CustomizePhone',
            action: 'Continue Customizer',
            dtmEvent: 'customizerPhone-Addition'
        },
        {
            eventName: 'CustomizeAccessory',
            action: 'Continue Customizer',
            dtmEvent: 'customizerAccessory-Addition'
        },
        {
            eventName: 'FE Error',
            dtmEvent: 'feerror'
        },
        {
            eventName: 'search-result-click',
            action: 'Search Results Click',
            dtmEvent: 'searchResultClick'
        },
        {
            eventName: 'lower-downpayment-addition',
            action: 'Lower Downpayment Item Addition',
            dtmEvent: 'LowerDownpayment-Addition'
        },
        {
            eventName: 'negotiable-item-removed',
            action: 'negotiable-item-removed',
            dtmEvent: 'send-Event'
        },
        {
            eventName: 'cart-item-addition',
            name: 'cart-item-addition',
            action: 'cart-item-addition',
            dtmEvent: 'send-Event'
        },
        {
            eventName: 'update-cart-item',
            name: 'update-cart-item',
            action: 'update-cart-item',
            dtmEvent: 'send-Event'
        },
        {
            eventName: 'remove-cart-item',
            name: 'remove-cart-item',
            action: 'remove-cart-item',
            dtmEvent: 'send-Event'
        },
        {
            eventName: 'xmppCart-Addition',
            name: 'xmppCart-Addition',
            action: 'xmppCart-Addition',
            dtmEvent: 'send-Event'
        },
        {
            eventName: 'decline-xmpp',
            dtmEvent: 'xmppCart-Addition'
        },
        {
            eventName: 'cbm-order',
            dtmEvent: 'cbm-order'
        },
        {
            eventName: 'user-detail',
            dtmEvent: 'user-detail'
        },
        {
            eventName: 'byo-continue',
            dtmEvent: 'byo-continue'
        }
    ]
};

export enum ByodEligibilityPages {
    compatible = 'compatible',
    incompatible = 'error-incompatible',
    compatibleUserAction = 'error-compatible-user-action',
    isApplelocked = 'error-applelocked',
    isAndroidLocked = 'error-androidlocked',
    updatesoftware = 'error-updatesoftware',
    existingline = 'error-existingline',
    lostStolen = 'error-lostStolen',
    LTEonly = 'error-LTEonly',
    SECONDARY_IMEI = 'error-secondaryIMEI'
}
