import { ModelBase } from 'store/model-base';
import { PlanPrice } from 'store/plan/models/plan-price';

export class PlanVariant extends ModelBase {
    public id: string;
    public price: PlanPrice;
    public overage: PlanPrice;
    public freeData: number;
    public unlimitedData: boolean;
    public rank: number;
    public sharedData: number;
    public legacyPlan: boolean;

    public get isValid(): boolean {
        return Boolean(this.id);
    }

    public get displayPrice(): string {
        if (this.price) {
            if (this.price.monthlyCharge) {
                return `<b>$${Math.round(this.price.monthlyCharge)}/mo</b>`;
            }

            return `$${this.price.oneTimeCharge.toFixed(2)}`;
        }
    }
}
