import { ChildModel, ModelBase } from 'store/model-base';
import { Variant } from 'store/product/models';
import { ProductDetail } from './detail';
import { ShippingMethod } from './shipping-method';
import { PriceOption } from 'store/plan/models';
import { APPLE_BRAND_NAME, ByodImeiCapability, ItemType, PlanSku, PlanType } from 'core/constants';
import { Util } from 'services/util';
import { PaymentPlanVariant } from 'store/plan/models/variant/payment';
import { ProtectionPlanVariant, RatePlanVariant } from 'store/plan/models/variant';
import { AdditionalCharges } from './additional-charges';

export interface productNames {
    name: string;
    brand: string;
}

export class CartItem extends ModelBase {
    public brand: string;
    public productDetail: ProductDetail;
    public eSimName: string;
    public fulfillmentMethod: string;
    public id: string;
    public itemType: ItemType;
    public oldLineId: string;
    public oldImei: string;
    public byod: boolean;
    public name: string;
    public shippingMethod: ShippingMethod;
    public isTaxCalculated: boolean;
    public variants: Variant[];
    public colors: ProductColor[];
    public price: RecurringPrice;
    public additionalCharges: AdditionalCharges[];
    public simType: ByodImeiCapability;
    public isESIM: boolean;
    public eSimItems: number;

    public prepaidPromo: PriceOption;
    public discountPromo: PriceOption;
    public payFullDiscountPromo: PriceOption;

    public hasDiscountPromo: boolean;
    public hasPrepaidPromo: boolean;
    public downPaymentAmount: number;
    public payOffAmount: number;
    public paymentTerm: number;
    public ndelPhoneNumber: string;
    public sku: string;
    public slug: string;

    public deviceCreditPrice: number;
    public deviceAccessFee: number;
    public eWasteFee: number;

    private _variant: Variant;

    /* eslint-disable @typescript-eslint/no-explicit-any */
    public static create<T extends ModelBase>(initData: ApiResponse): T {
        const item: TransformedData = initData;

        if (item.variants) {
            item._variant = item.variants.find((variant: Variant) => variant.skuCode === item.sku);

            const uniqueColors: ProductColor[] = [];
            const uniqueColorName: string[] = [];
            const uniqueColorHex: string[] = [];
            item.variants.forEach((variant: Variant) => {
                if (!uniqueColorName.includes(variant.color.name) || !uniqueColorHex.includes(variant.color.hex)) {
                    uniqueColors.push(variant.color);
                    uniqueColorName.push(variant.color.name);
                    uniqueColorHex.push(variant.color.hex);
                }
            });

            item.colors = uniqueColors;
        }

        const toReturn: CartItem = super.create<CartItem>(item);
        
        // ** REMOVE WHEN BILL CREDITS IS MERGED **
        // this is a hack/workaround as we support 2 api versions
        //
        // BAU -> has no slug property so default to name
        // Bill Credits -> has net-new slug property in cart api
        toReturn.slug = toReturn.slug || toReturn.name;

        toReturn.prepaidPromo = toReturn.variant.prices.find((price: PriceOption) => price.isPrepaidPromo);
        toReturn.discountPromo = toReturn.variant.prices.find((price: PriceOption) => (price.isPercentPromo || price.isDollarDiscount) && price.id === toReturn.productDetail.paymentPlanVariant.id);
        toReturn.payFullDiscountPromo = toReturn.variant.prices.find((price: PriceOption) => (price.isPercentPromo || price.isDollarDiscount) && price.id === PlanSku.PAY_IN_FULL);
        const priceOption: PriceOption = toReturn.variant.prices.find((price: PriceOption) => price.isFinanced);
        toReturn.downPaymentAmount = priceOption && priceOption.downPayment;
        toReturn.payOffAmount = priceOption && priceOption.payOffAmount;

        toReturn.hasDiscountPromo = Boolean(toReturn.discountPromo);
        toReturn.hasPrepaidPromo = Boolean(toReturn.prepaidPromo);
        toReturn.paymentTerm = toReturn.variant.getPaymentPlanById(toReturn.paymentPlanVariant.id).term;
        const deviceCredit: AdditionalCharges = toReturn.additionalCharges.find((additionalCharge: AdditionalCharges) => additionalCharge.isDeviceCredit);
        toReturn.deviceCreditPrice = deviceCredit && deviceCredit.price.monthlyRecurringCharge;

        const deviceAccessFee: AdditionalCharges = toReturn.additionalCharges.find((charge: AdditionalCharges) => charge.isDeviceAccessFee);
        toReturn.deviceAccessFee = deviceAccessFee && deviceAccessFee.price.monthlyRecurringCharge;

        const eWasteFee: AdditionalCharges = toReturn.additionalCharges.find((charge: AdditionalCharges) => charge.isEWasteFee);
        toReturn.eWasteFee = eWasteFee && eWasteFee.price.oneTimeCharge;

        return <T> <any> toReturn;
    }
    /* eslint-enable @typescript-eslint/no-explicit-any */

    protected static get hasOne(): ChildModel[] {
        return [{
            attrName: 'productDetail',
            model: ProductDetail
        }, {
            attrName: 'shippingMethod',
            model: ShippingMethod
        }, {
            attrName: '_variant',
            model: Variant
        }, {
            attrName: 'prepaidPromo',
            model: PriceOption
        }, {
            attrName: 'discountPromo',
            model: PriceOption
        }, {
            attrName: 'payFullDiscountPromo',
            model: PriceOption
        }];
    }

    protected static get hasMany(): ChildModel[] {
        return [{
            attrName: 'variants',
            model: Variant
        }, {
            attrName: 'additionalCharges',
            model: AdditionalCharges
        }];
    }

    public get displayName(): string {
        const displayName: string = `${this.brand} ${this.name}`;
        const color: string = this.variant.color && this.variant.color.name ? `, ${this.variant.color.name}, ` : '';
        const size: string = this.variant.capacity;

        return this.isAccessory ? displayName : `${displayName}${color}${size}`;
    }

    public get productName(): string {
        return `${this.brand} ${this.name}`;
    }

    public set productName(product: string) {
        this.brand = product.split(' ')[0];
        this.name = product.split(' ')[1];
    }

    public get isAppleProduct(): boolean {
        return this.brand === APPLE_BRAND_NAME;
    }

    public get paymentPlanVariant(): PaymentPlanVariant {
        return this.productDetail.paymentPlanVariant;
    }

    public set paymentPlanVariant(paymentPlan: PaymentPlanVariant) {
        this.productDetail.paymentPlanVariant = paymentPlan;
    }

    public get isEsim(): boolean {
        return this.byod && this.productDetail.isEsimCapable;
    }

    public get isUnlimitedIntro(): boolean {
        return this.productDetail.isUnlimitedIntro;
    }

    public get isUnlimitedPlus(): boolean {
        return this.productDetail.isUnlimitedPlus;
    }

    public get isUnlimitedPremium(): boolean {
        return this.productDetail.isUnlimitedPremium;
    }
    public get financedPlanVariant(): PaymentPlanVariant {
        return this.productDetail.financedPlanVariant;
    }

    public get payFullPlanVariant(): PaymentPlanVariant {
        return this.productDetail.payFullPlanVariant;
    }

    public get oneTimeCharge(): number {
        const oneTimeCost: number = this.productDetail.paymentPlanVariant.price && this.productDetail.paymentPlanVariant.price.oneTimeCharge;

        return this.byod ? 0 : oneTimeCost;
    }

    public get ratePlanVariant(): RatePlanVariant {
        return this.productDetail.ratePlanVariant;
    }

    public get basicPlanVariant(): RatePlanVariant[] {
        return this.productDetail.unlimitedIntroVariants;
    }

    public get plusPlanVariant(): RatePlanVariant[] {
        return this.productDetail.unlimitedPlusVariants;
    }

    public get premiumPlanVariant(): RatePlanVariant[] {
        return this.productDetail.unlimitedPremiumVariants;
    }

    // eslint-disable-next-line @typescript-eslint/adjacent-overload-signatures
    public set ratePlanVariant(ratePlan: RatePlanVariant) {
        this.productDetail.ratePlanVariant = ratePlan;
    }

    public get ratePlanVariantDisplay(): string {
        if (this.ratePlanVariant.isByTheGig) {
            return this.ratePlanVariant.legacyPlan ? `By the Gig: $${Math.round(this.ratePlanVariant.price.monthlyCharge)}/GB` :
                `By the Gig: ${this.ratePlanVariant.gigs}GB, ${this.ratePlanVariant.displayPrice}`;
        } else {
            return `Unlimited: $${Math.round(this.ratePlanVariant.price.monthlyCharge)}/Line`;
        }
    }

    public get protectionPlanVariant(): ProtectionPlanVariant {
        return this.productDetail.protectionPlanVariant;
    }

    public set protectionPlanVariant(protectionPlanVariant: ProtectionPlanVariant) {
        this.productDetail.protectionPlanVariant = protectionPlanVariant;
    }

    public get protectionPlanDisplay(): string {
        const protectionPlan: ProtectionPlanVariant = this.protectionPlanVariant;

        return protectionPlan && protectionPlan.isValid ? `Protection Plan, ${this.xmppVariant.displayPrice}` : 'No Protection Plan';
    }

    public get xmppCartDescription(): string {
        return this.protectionPlanVariant.cartInfo;
    }

    public get variant(): Variant {
        return this._variant;
    }

    public set variant(variant: Variant) {
        this._variant = variant;
        this.sku = variant.skuCode;
    }

    public get planMonthlyPrice(): number {
        const paymentPlanPrice: number = this.paymentPlanVariant && this.paymentPlanVariant.price ? this.paymentPlanVariant.price.monthlyCharge : 0;

        return paymentPlanPrice;
    }

    public get nickname(): string {
        return this.productDetail.nickname;
    }

    public get isPort(): boolean {
        return Boolean(this.productDetail && this.productDetail.isItemPorting);
    }

    public get displayPhoneNumber(): string {
        if (this.isDevice) {
            return this.isPort ? this.productDetail.portInfo.phoneNumber : 'New Number';
        }
    }

    public get phoneNumberType(): string {
        return this.isPort ? 'Ported Number' : 'New Number';
    }

    public get color(): ProductColor {
        return this.variant.color;
    }

    public get capacity(): string {
        return this.variant.capacity;
    }

    public get quantity(): number {
        return 1;
    }

    public get isDevice(): boolean {
        return this.itemType === ItemType.DEVICE;
    }

    public get isNdel(): boolean {
        return Boolean(this.oldImei && this.oldLineId);
    }

    public get isAccessory(): boolean {
        return this.itemType === ItemType.ACCESSORY;
    }

    public get isTablet(): boolean {
        return this.itemType === ItemType.TABLET;
    }

    public get isSmartWatch(): boolean {
        return this.itemType === ItemType.WATCH;
    }
     
    public get isOutOfStock(): boolean {
        return !this.byod && this.variant.isOutOfStock;
    }

    public get isPreorder(): boolean {
        return this.variant.isPreorder;
    }

    public get isBackorder(): boolean {
        return this.variant.isBackorder;
    }

    public get availabilityFormatedDate(): string {
        return Util.dateFormat(this.variant.availabilityDate.toString(), 'MMM dd, yyyy');
    }

    public get colorCapacityName(): string {
        return this.isDevice ? `${this.variant.color.name}, ${this.variant.capacity}` : this.variant.color.name;
    }

    public get hasSharedData(): boolean {
        return Boolean(this.ratePlanVariant.sharedData);
    }

    public variantByColorName(colorName: string): Variant {
        
        const uriColor: string = decodeURIComponent(colorName.replace(/\+/g, ' '));
        const result: Variant = this.variants.find((variant: Variant) => (variant.color.name === uriColor && !variant.isOutOfStock));

        return result || this.variants.find((variant: Variant) => variant.color.name === uriColor);

    }

    public variantByColorAndStorage(color: ProductColor, storage: string): Variant {
        const result: Variant = this.variants.find((variant: Variant) => variant.color.hex === color.hex && variant.capacity === storage && !variant.isOutOfStock);

        return result || this.variantByColorName(color.name);
    }

    public getPaymentPlanVariantById(id: PlanSku): PaymentPlanVariant {
        return id === PlanSku.FINANCED ? this.financedPlanVariant : this.payFullPlanVariant;
    }

    public ratePlanVariantById(id: string): RatePlanVariant {
        const availableRatePlanVariants: RatePlanVariant[] = [ ...this.sharedDataVariants, ...this.unlimitedVariants, ...this.gigVariants, ...this.rupVariants ];

        if (id.includes(':')) {
            const bucketId: string = id.split(':')[1];

            return availableRatePlanVariants.find((variant: RatePlanVariant) => variant.bucketId === bucketId);
        }

        return availableRatePlanVariants.find((variant: RatePlanVariant) => variant.id === id);

    }

    public getUnlimitedRatePlanVariant(): RatePlanVariant {
        return this.unlimitedVariants.find((variant: RatePlanVariant) => variant.id === this.productDetail.selectedPlanId);
    }

    public getUnlimitedRatePlanVariantById(id: string): RatePlanVariant {
        return this.unlimitedVariants.find((variant: RatePlanVariant) => variant.id === id);
    }

    public get sharedDataVariants(): RatePlanVariant[] {
        return this.productDetail.sharedDataVariants;
    }

    public get unlimitedVariants(): RatePlanVariant[] {
        return this.productDetail.unlimitedVariants;
    }
    public get rupVariants(): RatePlanVariant[] {
        return this.productDetail.rupVariants;
    }
    public get gigVariants(): RatePlanVariant[] {
        return this.productDetail.gigVariants;
    }

    public get xmppVariant(): ProtectionPlanVariant {
        return this.productDetail.xmppVariant;
    }

    public get monthlyDevicePaymentPrice(): number {
        return this.paymentPlanVariant.isFinanced ? this.variant.originalFinancePrice : 0;
    }

    public toApi(): object {
        if (this.isAccessory) {
            return {
                items: [{
                    id: this.id,
                    sku: this.sku
                }]
            };
        }

        let servicePlans: ServicePlan[] = [{
            planType: PlanType.PaymentPlan,
            selectedPlanId: this.paymentPlanVariant.id,
            serviceId: this.productDetail.paymentPlanServiceId
        }];

        if (((this.protectionPlanVariant && this.protectionPlanVariant.id) || this.productDetail.protectionPlanServiceId) && !this.byod) {
            servicePlans.push({
                planType: PlanType.ProtectionPlan,
                selectedPlanId: this.protectionPlanVariant && this.protectionPlanVariant.isValid ? this.protectionPlanVariant.id : undefined,
                serviceId: this.productDetail.protectionPlanServiceId
            });
        }

        if (this.ratePlanVariant && !this.isNdel && !this.isSmartWatch) {
            if (this.byod) {
                servicePlans = [];
            }

            servicePlans.push({
                planType: PlanType.RatePlan,
                selectedPlanId: this.ratePlanVariant.id,
                serviceId: this.productDetail.ratePlanServiceId
            });
        }
        
        return {
            items: [{
                id: this.id,
                sku: this.sku,
                productDetail: {
                    nickname: this.productDetail.nickname,
                    itemId: this.productDetail.itemId,
                    portInfo: !this.isNdel && this.isDevice ? {
                        phoneNumber: this.productDetail.isItemPorting ? this.productDetail.portInfo.phoneNumber : undefined,
                        carrierName: this.productDetail.isItemPorting ? this.productDetail.portInfo.carrierName : undefined,
                        portingFlag: this.productDetail.portInfo.portingFlag,
                        phoneNumberId: this.productDetail.portInfo.phoneNumberId
                    } : undefined,
                    servicePlans,
                    simType: this.byod ? this.isEsim ? ByodImeiCapability.ESIM : ByodImeiCapability.PSIM : undefined
                }
            }]
        };
    }
}