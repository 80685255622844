import { ChildModel, ModelBase } from 'store/model-base';
import { FullAddress, StreetAddress } from 'store/user/models';
import { ItemType, MBCMessageCodes, WatchOs } from 'core/constants';
import { ShippingMethod } from './shipping-method';
import { CalculatedTax } from './calculated-tax';
import { CartItem } from './item';
import { CartType } from 'buy/constants';
import { RatePlanVariant } from 'store/plan/models';

export class Cart extends ModelBase {
    public static storeName: string = 'cart';
    public id: string;
    public type: CartType;

    public items: CartItem[];
    public price: RecurringPrice;
    public monthlyCost: number;
    public taxes: CalculatedTax;
    public watchInCart: boolean;
    public androidWatchInCart: boolean;
    public appleWatchInCart: boolean;

    public deviceItems: CartItem[];
    public accessoryItems: CartItem[];
    public watchItems: CartItem[];
    public tabletItems: CartItem[];
    public deviceCount: number;
    public financedCount: number;
    public accessoryCount: number;
    public watchCount: number;
    public tabletCount: number;
    public linesCount: number;
    public accessoryTotal: number;
    public preorderCount: number;
    public backorderCount: number;

    public contactEmail: string;
    public contactNumber: string;
    public shippingAddress: FullAddress;
    public shippingMethod: ShippingMethod;

    public hasPhone: boolean;
    public hasAccessory: boolean;
    public hasRatePlanDevice: boolean;
    public hasByTheGig: boolean;
    public unlimitedVariants: RatePlanVariant[];
    public unlimitedIntroVariants: CartItem[];
    public unlimitedPlusVariants: CartItem[];
    public unlimitedPremiumVariants: CartItem[];
    
    public hasXmpp: boolean;
    public hasEIP: boolean;
    public hasPreorder: boolean;
    public hasBackorder: boolean;
    public hasNdel: boolean;
    public hasOnlyAccessory: boolean;
    public byodCount: number;
    public byodPortCount: number;
    public hasOnlyByod: boolean;
    public hasOnlyByodDevice: boolean;
    public hasOnlyByodItems: boolean;
    public hasByod: boolean;
    public hasByodPortItems: boolean;
    public messages: Message[];
    public hasDataIncluded: boolean;
    public lastIphoneInCart: boolean;
    public lastAndroidPhoneInCart: boolean;
    public hasTalkAndTextItems: boolean;

    public forceCreditCheck: boolean;
    public partialSuccessCreditCheck: boolean;
    public hardStopCreditCheck: boolean;
    public passesCreditCheck: boolean;
    public unavailableCreditCheck: boolean;
    public hasFullDownPayments: boolean;
    public hasExtendedPromo: boolean;
    public watchBuyIneligible: boolean;
    public appleWatchIneligible: boolean;
    public androidWatchIneligible: boolean;
    public hasDownPaymentItem: boolean;
    public isSimLedType: boolean;

    public byTheGigItems: CartItem[];
    public unlimitedItems: CartItem[];
    public sharedDataItems: CartItem[];
    public eSimItems: CartItem[];
    
    public eSimCount: number;
    public hasOnlyEsimItems: boolean;

    /* eslint-disable no-param-reassign */
    public static create<T extends ModelBase>(initData: object = {}): T {
        const toReturn: Cart = super.create<Cart>(initData);

        toReturn.isSimLedType = toReturn.type === CartType.SIMLED_BYOD;
        toReturn.monthlyCost = toReturn.price ? toReturn.price.monthlyRecurringTotal : 0;

        toReturn.deviceItems = toReturn.items.filter((item: CartItem) => item.isDevice);
        toReturn.financedCount = toReturn.items.filter((item: CartItem) => item.downPaymentAmount > 0).length;
        toReturn.deviceCount = toReturn.deviceItems.length;
        toReturn.accessoryItems = toReturn.items.filter((item: CartItem) => item.isAccessory);
        toReturn.accessoryCount = toReturn.accessoryItems.length;
        toReturn.watchItems = toReturn.items.filter((item: CartItem) => item.isSmartWatch);
        toReturn.watchCount = toReturn.watchItems.length;
        toReturn.tabletItems = toReturn.items.filter((item: CartItem) => item.isTablet);
        toReturn.tabletCount = toReturn.tabletItems.length;
        toReturn.linesCount = toReturn.items.filter((item: CartItem) => !item.isAccessory).length;
        toReturn.byTheGigItems = toReturn.items.filter((item: CartItem) => item.ratePlanVariant.isByTheGig);
        toReturn.unlimitedItems = toReturn.items.filter((item: CartItem) => (item.isDevice || item.isTablet) && item.ratePlanVariant.isUnlimited);
        toReturn.unlimitedIntroVariants = toReturn.items.filter((item: CartItem) => item.isUnlimitedIntro);
        toReturn.unlimitedPlusVariants = toReturn.items.filter((item: CartItem) => item.isUnlimitedPlus);
        toReturn.unlimitedPremiumVariants = toReturn.items.filter((item: CartItem) => item.isUnlimitedPremium);
        
        toReturn.hasTalkAndTextItems = Boolean(toReturn.deviceCount || toReturn.watchCount);

        toReturn.accessoryTotal = toReturn.accessoryItems.map((cartItem: CartItem) => cartItem.oneTimeCharge).reduce((total: number, dueToday: number) => total += dueToday, 0);
        toReturn.preorderCount = toReturn.items.filter((item: CartItem) => item.isPreorder && item.isDevice).length;
        toReturn.backorderCount = toReturn.items.filter((item: CartItem) => item.isBackorder).length;
        toReturn.byodCount = toReturn.items.filter((item: CartItem) => item.byod).length;
        toReturn.byodPortCount = toReturn.items.filter((item: CartItem) => item.byod && item.isPort).length;
        toReturn.hasOnlyByod = toReturn.byodCount === toReturn.items.length;
        toReturn.hasOnlyByodDevice = toReturn.byodCount === toReturn.deviceItems.length;
        toReturn.hasOnlyByodItems = toReturn.items.every((item: CartItem) => item.byod);
        toReturn.hasByod = Boolean(toReturn.byodCount);
        toReturn.hasByodPortItems = Boolean(toReturn.byodPortCount);

        toReturn.hasPhone = toReturn.items.some((item: CartItem) => item.itemType === ItemType.DEVICE);
        toReturn.hasAccessory = toReturn.items.some((item: CartItem) => item.itemType === ItemType.ACCESSORY);
        toReturn.hasRatePlanDevice = toReturn.items.some((item: CartItem) => item.itemType !== ItemType.ACCESSORY);
        toReturn.hasXmpp = toReturn.items.some((item: CartItem) => Boolean(item.protectionPlanVariant.isValid));
        toReturn.hasEIP = toReturn.items.some((item: CartItem) => item.paymentPlanVariant.isFinanced);
        toReturn.hasPreorder = toReturn.items.some((item: CartItem) => item.variant.isPreorder);
        toReturn.hasBackorder = toReturn.items.some((item: CartItem) => item.variant.isBackorder);
        toReturn.hasNdel = toReturn.items.some((item: CartItem) => item.isNdel);
        toReturn.hasOnlyAccessory = Boolean(toReturn.accessoryCount === toReturn.items.length);
        toReturn.hasDataIncluded = toReturn.deviceItems.some((device: CartItem) => Boolean(device.ratePlanVariant.freeData));
        toReturn.hasByTheGig = toReturn.deviceItems.some((item: CartItem) => item.ratePlanVariant.isByTheGig);

        toReturn.messages = toReturn.messages || [];
        toReturn.watchBuyIneligible = toReturn.messages.some((message: Message) => message.code === MBCMessageCodes.WATCH_BUY_INELIGIBLE);
        toReturn.appleWatchIneligible = toReturn.messages.some((message: Message) => message.code === MBCMessageCodes.WATCH_BUY_INELIGIBLE && message.os === WatchOs.APPLE);
        toReturn.androidWatchIneligible = toReturn.messages.some((message: Message) => message.code === MBCMessageCodes.WATCH_BUY_INELIGIBLE && message.os === WatchOs.ANDROID);
        toReturn.lastIphoneInCart = toReturn.deviceItems.filter((item: CartItem) => item.isAppleProduct).length === 1;
        toReturn.lastAndroidPhoneInCart = toReturn.deviceItems.filter((item: CartItem) => !item.isAppleProduct).length === 1;
        toReturn.forceCreditCheck = toReturn.messages.some((message: Message) => message.code === MBCMessageCodes.FORCE_ECC);
        toReturn.partialSuccessCreditCheck = toReturn.messages.some((message: Message) => message.code === MBCMessageCodes.ILC_PARTIAL_SUCCESS || message.code === MBCMessageCodes.ECC_PARTIAL_SUCCESS);
        toReturn.hardStopCreditCheck = toReturn.messages.some((message: Message) => message.code === MBCMessageCodes.CREDIT_CHECK_HARD_STOP);
        toReturn.passesCreditCheck = !toReturn.messages.length;
        toReturn.unavailableCreditCheck = toReturn.messages.some((message: Message) => message.code === MBCMessageCodes.CREDIT_CHECK_UNAVAILABLE);

        toReturn.sharedDataItems = toReturn.items.filter((item: CartItem) => item.hasSharedData);
        toReturn.hasDownPaymentItem = toReturn.items.some((item: CartItem) => item.downPaymentAmount > 0);
        toReturn.hasFullDownPayments = toReturn.deviceItems.filter((item: CartItem) => item.downPaymentAmount > 0).length === toReturn.deviceCount;

        toReturn.hasExtendedPromo = Boolean(toReturn.items.find((item: CartItem) => Boolean(item.variant.extendedPromo)));

        toReturn.eSimItems = toReturn.items.filter((item: CartItem) => item.isEsim);
        toReturn.eSimCount = toReturn.eSimItems.length;
        toReturn.hasOnlyEsimItems = Boolean(toReturn.eSimCount === toReturn.items.length);

        toReturn.androidWatchInCart = toReturn.watchItems.some((item: CartItem) => !item.isAppleProduct);
        toReturn.appleWatchInCart = toReturn.watchItems.some((item: CartItem) => item.isAppleProduct);

        /* eslint-disable @typescript-eslint/no-explicit-any */
        return <T> <any> toReturn;
        /* eslint-enable @typescript-eslint/no-explicit-any */
    }
    /* eslint-enable no-param-reassign */

    protected static get hasMany(): ChildModel[] {
        return [{
            attrName: 'items',
            model: CartItem
        }, {
            attrName: 'deviceItems',
            model: CartItem
        }, {
            attrName: 'accessoryItems',
            model: CartItem
        }, {
            attrName: 'watchItems',
            model: CartItem
        }, {
            attrName: 'tabletItems',
            model: CartItem
        }, {
            attrName: 'byTheGigItems',
            model: CartItem
        }, {
            attrName: 'unlimitedItems',
            model: CartItem
        }, {
            attrName: 'sharedDataItems',
            model: CartItem
        }];
    }

    protected static get hasOne(): ChildModel[] {
        return [{
            attrName: 'shippingAddress',
            model: FullAddress
        }, {
            attrName: 'shippingMethod',
            model: ShippingMethod
        }, {
            attrName: 'taxes',
            model: CalculatedTax
        }];
    }

    public get prorationDetails(): ProrationDetails {
        return {
            paymentPlan: true,
            ratePlan: true,
            protectionPlan: true
        };
    }

    public get dueToday(): number {
        const due: number = this.price ? this.price.oneTimeCharge + this.price.oneTimeTax : 0;

        return this.shippingMethod.price ? due + this.shippingMethod.price : due;
    }

    public get displayName(): string {
        return `${this.shippingAddress.firstName} ${this.shippingAddress.lastName}`;
    }

    public get fullShippingAddress(): string {
        const shippingAddress: StreetAddress = this.shippingAddress.address;
        shippingAddress.address2 = shippingAddress.address2 || '';

        return `${shippingAddress.address1} ${shippingAddress.address2} ${shippingAddress.city} ${shippingAddress.state} ${shippingAddress.zip}`;
    }

    public get selectedSharedPlanId(): string {
        return this.sharedDataItems.length && this.sharedDataItems[0].ratePlanVariant.id;
    }

    public itemById(id: string): CartItem {
        return this.items.find((item: CartItem) => item.id === id);
    }

    public get prepaidPromoTotal(): number {
        const items: CartItem[] = this.items.filter((item: CartItem) => item.hasPrepaidPromo);
        if (items.length) {
            /* eslint-disable no-param-reassign */
            return items.map((item: CartItem) => item.prepaidPromo.promotionalValue).reduce((sum: number, promotionalValue: number) => sum += promotionalValue) || items.length;
            /* eslint-enable no-param-reassign */
        }

        return 0;
    }
}
