import { ModelBase } from 'store/model-base';

export class CmsFinancingOptions extends ModelBase {
    public static storeName: string = 'cmsfinancingOptions';

    public alertHeader: string;
    public underAlertHeader: string;
    public underAlertParagraph: string;
    public alertParagraph: string;
    public personalLiability: CmsLiabilityType;
    public businessLiability: CmsLiabilityType;
    public preApprovedOffer: CmsLiabilityType;
    public noOfferSubHeader: string;
    public defaultSubHeader: string;
    public defaultIncreaseSubHeader: string;
    public noOfferHeader: string;
    public specialSupport: CmsLiabilityType;
    public header: string;
    public cpoHeader: string;
    public einMessage: string;
    public bulletEligibilityOneBold: string;
    public bulletEligibilityOne: string;
    public bulletEligibilityTwo: string;
    public bulletEligibilityTwoBold: string;
    public einLink: string;
    public businessHeader: string;
    public personalHeader: string;
    public businessSubHeader: string;
    public personalSubHeader: string;
    public businessContinueBtn: string;
    public yourEligibility: string;
    public personalContinueBtn: string;
    public cpoChooseOption: string;
    public cpoRunCreditCheck: string;
    public cpoEditCart: string;
    public cpoReRunCreditCheckLink: string;
    public cpoPreApprovedHeader: string;
    public cpoSubPreApprovedHeader: string;
    public message: string;
    public cpoMessage: string;
    public subheader: string;
    public viewEligibility: string; 
    public plOptionImage: CmsMediaImage;
    public continueBtn: string;
    public backBtn: string;
    public deniedHeader: string;
    public deniedMessage: string;
    public noBlOfferCreditHeader: string;
    public noBLOfferCreditHeaderMessage: string;
    public noPlOfferCreditHeader: string;
    public noPlOfferCreditHeaderMessage: string;
}
