import { ChildModel, ModelBase } from 'store/model-base';
import { LineEligibilityConditionalCodes as Codes, XmppStatus } from 'core/constants';
import { NdelProductDetails } from './ndel-product-details';
import { DevicePaymentPlan } from './device-payment-plan';
import { XmppPlan } from './xmpp-plan';

export class NdelLineEligibility extends ModelBase {
    public static storeName: string = 'ndelLineEligibility';

    public lineId: Id;
    public ndelEligible: boolean;
    public upgradeEligible: boolean;
    public upgradeFee: number;
    public imei: string;
    public sku: string;
    public upgradeEligibleDppAmountDue: number;
    public currentDevicePurchasePrice: number;
    public currentDeviceRemainingBalance: number;
    public eligibilityConditionalReason: EligibilityReason[];
    public eligibilityDenialReason: EligibilityReason[];
    public byod: boolean;
    public displayName: string;
    public lineNumber: string;
    public devicePaymentPlan: DevicePaymentPlan;
    public insurancePlan: XmppPlan;
    public productDetails: NdelProductDetails;

    public hasDpp: boolean;
    public hasProtectionPlan: boolean;
    public paidHalf: boolean;
    public isPaidInFull: boolean;
    public balancePaid: boolean;
    public productName: string;

    public static create<T extends ModelBase>(initData: ApiResponse): T {
        const toReturn: NdelLineEligibility = super.create<NdelLineEligibility>(initData);

        toReturn.hasDpp = toReturn.devicePaymentPlan && toReturn.devicePaymentPlan.hasDPP;
        toReturn.balancePaid = !toReturn.devicePaymentPlan || !toReturn.devicePaymentPlan.balance;
        toReturn.hasProtectionPlan = toReturn.insurancePlan && (toReturn.insurancePlan.status === XmppStatus.ACTIVE);
        toReturn.paidHalf = !toReturn.eligibilityConditionalReason.some((eligibility: EligibilityReason) => eligibility.code === Codes.PAID_HALF);
        toReturn.hasProtectionPlan = toReturn.eligibilityConditionalReason.some((eligibility: EligibilityReason) => eligibility.code === Codes.HAS_XMPP);
        toReturn.isPaidInFull = toReturn.eligibilityDenialReason.some((eligibility: EligibilityReason) => eligibility.code === Codes.PAID_IN_FULL);
        toReturn.productName = toReturn.productDetails && `${toReturn.productDetails.model}, ${toReturn.productDetails.colorName}, ${toReturn.productDetails.capacity}`;

        /* eslint-disable @typescript-eslint/no-explicit-any */
        return <T> <any> toReturn;
        /* eslint-enable @typescript-eslint/no-explicit-any */
    }

    protected static get hasOne(): ChildModel[] {
        return [{
            attrName: 'devicePaymentPlan',
            model: DevicePaymentPlan
        }, {
            attrName: 'productDetails',
            model: NdelProductDetails
        }, {
            attrName: 'insurancePlan',
            model: XmppPlan
        }];
    }
}
