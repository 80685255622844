import { Component, Input } from '@angular/core';

@Component({
    selector: 'xm-status',
    styleUrls: [ './status.scss' ],
    templateUrl: './status.html'
})

export class XmStatusComponent {
    @Input() public statusMessage: string;
    @Input() public imagePath: string;
    @Input() public imageAlt: string;
}